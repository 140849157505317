import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { messagingActions } from '../../redux/actions'
import moment from 'moment'
import { config } from '../../config'
import InfiniteScroll from 'react-infinite-scroll-component'
import VisibilitySensor from 'react-visibility-sensor'


const MessagesList = () => {
  const messagesReducer = useSelector(state => state.messagingReducer)
  const dispatch = useDispatch()

  const { messages, chat, meta, cable } = messagesReducer
  const user = useSelector(state => state.authReducer.user)

  const userChannelConnected = () => console.log('connected')
  const userChannelDisconnected = () => console.log('disconnected')
  const userChannelReceived = data => {
    dispatch(messagingActions.receivedMessage(data.message))
  }

  console.log(cable)

  useEffect(() => {
    if(chat.id && cable) {
      var sub =  cable.subscriptions.create({ channel: "ChatChannel", chat_id: chat.id }, {
        connected: userChannelConnected,
        disconnected: userChannelDisconnected,
        received: userChannelReceived,
      })
    }

    dispatch(messagingActions.getMessages({ chat_id: chat.id, date: messages[messages.length-1]?.created_at} ))

    return () => {
      if(sub) {
        cable.subscriptions.remove(sub)
      }
    }
  
  }, [chat, cable])
  
  const fisrtItem = messages[0]

  const fetchNext = (isVisible, id) => {

    // if(id === fisrtItem.id) {
      if(isVisible){
        dispatch(messagingActions.getMessages({ chat_id: chat.id, date: messages[messages.length-1].created_at} ))
      }
    // }

    console.log(id)
  }


  console.log(messages?.length)

  return (
    <div className="messages-list" id="messageList">
      { messages.map((message, i) => {
        const hasImg = message.attachment_url || message?.body?.includes('[gif]')
        const isGif = message?.body?.includes('[gif]');
          let img = message.attachment_url
          console.log(messages[messages.length - 1])

          return messages.length - i !== 1 || meta.finished  ? (
            <div className={message.sender.id === user.id ? 'message message--sent' : 'message message--recived'} >
              <p className="message__text">{isGif?'':message.body}</p>
              { hasImg && <img className="message__image" src={isGif? message.body.slice(5):config.REACT_APP_IMAGE_URL + img} alt="message image"/> }
              <span className="message__date">{moment(message.created_at).fromNow()}</span>
            </div>
          ) : (
            <VisibilitySensor scrollCheck scrollDelay={500} scrollCheck onChange={(isVisible) => fetchNext(isVisible, message.id)}>
              <div  className={message.sender.id === user.id ? 'message message--sent' : 'message message--recived'} >
                <p className="message__text">{isGif?'':message.body}</p>
                { hasImg && <img className="message__image" src={isGif? message.body.slice(5):config.REACT_APP_IMAGE_URL + img} alt="message image"/> }
              <span className="message__date">{moment(message.created_at).fromNow()}</span>
              </div>
            </VisibilitySensor>
          )
      })}
      </div>
  )
}

export default MessagesList
