import React from 'react'
import SearchComponent from '../components/Search/SearchComponent'
import Sidebar from '../components/Sidebar/Sidebar'
import Header from '../components/Header'
import AppWrapper from '../components/AppWrapper';

const Search = () => {
  return (
    <AppWrapper>
    <svg style={{width: '0', height: '0', position: 'absolute'}} aria-hidden="true" focusable="false">
      <linearGradient id="my-cool-gradient" x2="1" y2="0">
      <stop offset="0%" stopColor="#e25867" />
      <stop offset="100%" stopColor="#e88969" />
    </linearGradient>
    </svg>
    <Header />

    <div className="page__wrapper page__bg page__bg--blur feed-page">
      <div className="page__feed">
        <Sidebar />
        <SearchComponent />
      </div>
    </div>
    </AppWrapper>
  )
}

export default Search
