import React from 'react'
import { config } from '../../config'
import PostComments from './PostComments'
import Slider from "react-slick";
import PostSlide from './PostSlide';
import Carousel from 'nuka-carousel';

// @todo make post better on latptops

const Post = ({ post }) => {

  let thumb_img = post.attachments[0].thumb_url
  let img =  post.attachments[0].attachment_url

  // const settings = {
  //     className: "",
  //     dots: false,
  //     infinite: true,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     adaptiveHeight: true
  // };

   const defaultControlsConfig = {
    nextButtonText: '→',
    prevButtonText: '←',
    pagingDotsStyle: {
      fill: '#ea4459',
      marginRight: '5px'
    }
  }
  
  return (
    <div className="post">
      <Carousel enableKeyboardControls height="100%" width="70%" className="custom-slider"
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      defaultControlsConfig={defaultControlsConfig}>
        { post.attachments.map(slide => (
          <PostSlide post={post} img={slide.attachment_url} thumbImg={slide.thumb_url} />
         )) }
      </Carousel>
      <PostComments id={post.id} replies={post.replies} />
    </div>
  )
}

export default Post
