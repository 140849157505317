import {EVENTS_CONSTS, INITIAL_STATE} from '../constants';

const eventsReducer = (state = INITIAL_STATE.EventsInitialState, action) => {
  const {type, payload} = action || {};
  const {events, upcoming_events, event, error} = payload || {};
  switch (type) {
    case EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: events || [],
      };
    case EVENTS_CONSTS.EVENTS_ACTIONS.GET_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        upcoming_events: upcoming_events || [],
      };
    case EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENT_SUCCESS:
      return {
        ...state,
        event: event || null,
      };
    case EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENTS_ERROR:
    case EVENTS_CONSTS.EVENTS_ACTIONS.GET_UPCOMING_EVENTS_ERROR:
    case EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENT_ERROR:
      return {
        ...state,
        error: error || '',
      };
    default:
      return state;
  }
};

export {eventsReducer};