import React from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../constants/ROUTES'
import './Sidebar.css'

import { ReactComponent as HomeIcon } from '../../imgs/icons/i-home.svg'
import { ReactComponent as MessageIcon } from '../../imgs/icons/i-message.svg'
import { ReactComponent as SearchIcon } from '../../imgs/icons/i-search.svg'
import { ReactComponent as ProfileIcon } from '../../imgs/icons/i-profile.svg'
import { ReactComponent as SettingsIcon } from '../../imgs/icons/i-settings.svg'

const Sidebar = () => {
  return (
    <aside className="page__sidebar">
      <nav className="sidebar__nav">
        <ul className="menu__list">
          <li className="menu__item">
            <NavLink to={ROUTES.FEED} className="menu__link">
              <HomeIcon className="i40x40 nav__icon" />
              FEED
            </NavLink>
          </li>
          <li className="menu__item">
            <NavLink to={ROUTES.CHATS} className="menu__link">
              <MessageIcon className="i40x40 nav__icon" />
              MESSAGES
            </NavLink>
          </li>
          <li className="menu__item">
            <NavLink to={ROUTES.SEARCH} className="menu__link">
              <SearchIcon style={{ transform: 'scale(2)', marginLeft: '10px',  marginRight: '43px' }} className="i40x40 nav__icon" />
              SEARCH
            </NavLink>
          </li>
        </ul>
        <ul className="menu__list">
          <li className="menu__item">
            <NavLink to={ROUTES.PROFILE} className="menu__link">
              <ProfileIcon className="i40x40 nav__icon" />
              MY PROFILE
            </NavLink>
          </li>
          <li className="menu__item">
            <NavLink to={ROUTES.SETTINGS} className="menu__link">
              <SettingsIcon className="i40x40 nav__icon" />
              SETTINGS
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  )
}

export default Sidebar
