import React, { useEffect } from 'react'
import { authActions, messagingActions } from '../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../services/LocalStorage'
import actioncable from 'actioncable'
import { config } from '../config'

const PageWrapper = ({ children }) => {
  const cable = useSelector(state => state.messagingReducer.cable)
  const user = useSelector(state => state.authReducer.user)
  const token = getToken()
  const dispatch = useDispatch()
  const userChannelConnected = () => console.log('connected')
  const userChannelDisconnected = () => console.log('disconnected')
  const userChannelReceived = data => {
    // if(user.id !== data.chat.last_message.sender.id) {
    //   dispatch(messagingActions.receivedChat(data.chat))
    // } else {
    //   dispatch(messagingActions.updateChat(data.chat))
    // }
    dispatch(messagingActions.receivedChat(data.chat));

  }

  useEffect(() => {
   dispatch(authActions.isAuthenticated())
    if(token) {
      const userCable = actioncable.createConsumer(`${config.REACT_APP_WEBSOCKET_URL}/cable?token=${token}`,)
      dispatch(messagingActions.webSocketConnection(userCable))
    }
  }, [])

  useEffect(() => {
    if (cable && user) {
      let cb = cable.subscriptions.create({ channel: "UserChannel", user_id: user.id }, {
        connected: userChannelConnected,
        disconnected: userChannelDisconnected,
        received: userChannelReceived,
      });
    }
  }, [cable, user])
  // const history = useHistory()

	// useEffect(() => {
	// 	if(isAuthenticated) {
	// 		history.push('/feed')
	// 	}
	// }, [isAuthenticated])

  return children;
}

export default PageWrapper;
