import {EVENTS_CONSTS} from '../constants';

export const getEvents = () => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENTS,
  payload: {},
});

export const getEventsSuccess = (events) => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENTS_SUCCESS,
  payload: {events},
});

export const getEventsError = (error) => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENTS_ERROR,
  payload: {error},
});

export const getUpcomingEvents = () => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_UPCOMING_EVENTS,
  payload: {},
});

export const getPastEvents = () => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_PAST_EVENTS,
  payload: {},
})

export const getUpcomingEventsSuccess = (upcoming_events) => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_UPCOMING_EVENTS_SUCCESS,
  payload: {upcoming_events},
});

export const getUpcomingEventsError = (error) => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_UPCOMING_EVENTS_ERROR,
  payload: {error},
});

export const getEvent = (id) => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENT,
  payload: {id},
});

export const getEventSuccess = (event) => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENT_SUCCESS,
  payload: {event},
});

export const getEventError = (error) => ({
  type: EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENT_ERROR,
  payload: {error},
});
