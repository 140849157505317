import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { profileActions } from '../../redux/actions'
import Popup from '../Popup'
import { useForm } from "react-hook-form";


const SettingPopup = ({ open, handleClose }) => {
  // const [open, setOpen] = useState()
  const dispatch = useDispatch()
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onBlur' });
  const PASSWORD = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/;
  const currentPassword = watch("password", "")

  const stopPropagation = e => {
    e.stopPropagation()
  }

  const onSubmit = data => {
    dispatch(profileActions.updatePassword({ old_password: data.oldPassword, password: data.password, password_2: data.repeatPassword }))
    handleClose()
  }

  return (
    <Popup open={open} handleClose={handleClose} className="profile-popup setting-popup">
        <h1>Set new Password</h1>
        <p>Must be minimum of 6 characters long, with at least one capital letter and one number</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="profile-popup__inputs">
            <input type="password" placeholder="Old password" { ...register('oldPassword', { required: true}) } />
            <input type="password" placeholder="New password" { ...register('password', { required: true, pattern: PASSWORD })} />
            { errors.password && <p>Password must be minimum of 6 characters long, with at least one capital letter and one number</p> }
            <input type="password" placeholder="Repeat new password" { ...register('repeatPassword', { required: true, validate: value => value === currentPassword || "The passwords do not match" }) } />
            { errors.repeatPassword && <p>{ errors.repeatPassword.message }</p> }
          </div>
          <input type="submit"  value="CONFIRM" />
          { errors.passowrd && <p>{errors.passowrd.message}</p> }
        </form>
    </Popup>
  )
}

export default SettingPopup