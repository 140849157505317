import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { postsActions } from '../../redux/actions'

const PostSelect = () => {

  const meta = useSelector(state => state.postsReducer.meta)
  const { tab } = meta
  // const tab = useSelector(state => state.postsReducer.meta.tab)
  const dispatch = useDispatch()

  const handleTabChange = tab => {
    dispatch(postsActions.getPosts({ ...meta, tab: tab, page: 1 }))
  }

  console.log(tab)
  return (
    <div className="posts__select">
      <ul className="tabs__list">
        <li onClick={() => handleTabChange('following')} className="menu__item" style={tab == 'following' ? { borderColor: '#ea4459' } : {}}>FOLLOWING
          {/* <span className="menu__dot" style={{ backgroundColor: '#ea4459' }} >.</span> */}
        </li>
        <li onClick={() => handleTabChange('world')} className="menu__item" style={tab == 'world' ? { borderColor: '#92d148' } : {}}>WORLD
          {/* <span className="menu__dot" style={{ backgroundColor: '#92d148' }}>.</span> */}
        </li>
        <li onClick={() => handleTabChange('events')} className="menu__item"  style={tab == 'events' ? { borderColor: '#f09235' } : {}}>EVENTS
          {/* <span className="menu__dot" style={{ backgroundColor: '#f09235' }}>.</span> */}
        </li>
        <li onClick={() => handleTabChange('popular')} className="menu__item"  style={tab == 'popular' ? { borderColor: '#4a97f7' } : {}}>POPULAR
          {/* <span className="menu__dot" style={{ backgroundColor: '#4a97f7' }}>.</span> */}
        </li>
      </ul>
    </div>
  )
}

export default PostSelect
