import React from 'react'
import {Link} from 'react-router-dom';

const Event = ({ id, date, title, description, price, place }) => {
  return (
    <Link class="list__item" to={`/event/${id}`}>
      <div class="card">
        <div class="card__date">
          <div class="date">
            <div class="date__bar date__bar--upcoming"></div>
            <div class="event__day">{new Date(date).getDate()}</div>
            <div class="event__month">{new Date(date).toLocaleDateString('en-us', {month: 'short'})}</div>
          </div>
        </div>
        <div class="card__text">
          <div class="text">
            <div class="event__title">
              { title }
            </div>
            <div class="event__desciption">
              {description}
            </div>
          </div>
        </div>
        <div class="card__info">
          <div class="info">
            <div class="event__price">
              <span class="label">Price:</span>
              <span class="text">{price} €</span>
            </div>
            <div class="event__place">
              <span class="label">Place:</span>
              <span class="text">{place}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Event
