import React, {useEffect} from 'react';
import moment from 'moment';
import {config} from '../config';
import OtherEvent from '../components/UpcomingEvent';
import DownloadLinks from '../components/DownloadLinks';
import Header from '../components/LandingHeader';
import LoggedHeader from '../components/Header'
import Footer from '../components/LandingFooter';

const Event = ({
  getEvent,
  event,
  location,
  user,
  showLoginModal,
  toggleLoginModal,
  history,
}) => {
  useEffect(() => {
    const {pathname} = location;
    const id = pathname.replace('/event/', '');
    getEvent(id);
  }, []);
  useEffect(() => {
    const {pathname} = location;
    const id = pathname.replace('/event/', '');
    getEvent(id);
  }, [location]);
  const handleBuyTicket = () => {
    if (user)
      history.push(`/buy-ticket/${event?.id}`);
    else if (!user) {
      localStorage.setItem('event', event?.id);
      history.push('/login');
    }
  };
  return (
    <div className="page__wrapper_landing">
      {user ? <LoggedHeader /> : <Header />}
      <div className="content">
        {/* <!--Event--> */}
        <section className="event">
          <figure className="event__image">
            <img
              src={
                event?.image_url
                  ? `${config.REACT_APP_IMAGE_URL}${event?.image_url}`
                  : ''
              }
              alt={event?.title}
            />
          </figure>
          <div className="event__content">
            <div className="site-width">
              <div className="event__columns">
                <div className="event__column">
                  <h1 className="event__title">{event?.title}</h1>
                  <div className="event__author">
                    by <strong>{event?.owner.full_name}</strong>
                  </div>
                  <div className="event__info">
                    <div className="info__item event__location">
                      <i className="icons i24x24 i-location"></i>
                      {event?.location}
                    </div>
                    <div className="info__item event__location">
                      <i className="icons i24x24 i-time"></i>
                      {moment(event?.start_date).format('ddd, MMM D, H:mm')}
                    </div>
                    <div className="info__item event__location">
                      <i className="icons i24x24 i-label"></i>
                      {`${event?.price} EUR`}
                    </div>
                  </div>
                  <div className="event__btns">
                    <button
                      className="btns btn-gradient"
                      onClick={handleBuyTicket}
                      disabled={event?.tickets_available_count === 0}
                      data-toggle="class">
                      {event && event?.tickets_available_count > 0
                        ? 'Buy ticket'
                        : 'Sold out'}
                    </button>
                  </div>
                  <div className="event__description">
                    <div className="event__label">Description:</div>
                    <p>{event?.description}</p>
                  </div>
                </div>
                <div className="event__column">
                  <div className="event__map">
                    <div className="event__label">Event location:</div>
                    <div className="map">
                      {event && event.location && (
                        <iframe
                          src={`https://www.google.com/maps/embed/v1/search?key=${config.REACT_APP_MAPS_API_KEY}&q=${event?.location}`}
                          width="600"
                          height="450"
                          frameBorder="0"
                          style={{border: 0}}
                          allowFullScreen={false}
                          aria-hidden="false"
                          tabIndex={0}
                        />
                      )}
                    </div>
                  </div>
                  <div className="event__share">
                    <div className="event__label">Share with friends</div>
                    <ul className="share__list">
                      <li className="share__item">
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                            window.location.href,
                          )}`}
                          target="_blank"
                          className="share__link">
                          <i className="icons i24x24 i-facebook"></i>
                        </a>
                      </li>
                      {/* <li className="share__item">
                        <a
                          href={`https://www.instagram.com/sharer.php?u=${encodeURIComponent(
                            (config.REACT_APP_IMAGE_URL || '') + event?.image_url,
                          )}`}
                          target="_blank"
                          className="share__link">
                          <i className="icons i24x24 i-instagram"></i>
                        </a>
                      </li> */}
                      <li className="share__item">
                        <a
                          href={`https://twitter.com/share?url=${encodeURIComponent(
                            window.location.href,
                          )}`}
                          target="_blank"
                          className="share__link">
                          <i className="icons i24x24 i-twitter"></i>
                        </a>
                      </li>
                      {/* <li className="share__item">
                        <a href="#" className="share__link">
                          <i className="icons i24x24 i-share"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--/Event--> */}
        {/* <!--Events New--> */}
        <section className="events-new">
          <div className="site-width">
            <div className="section__title">Other Events You may Like</div>
            <div className="events__list">
              {event?.may_like_events.map((event) => (
                <OtherEvent key={event.id} event={event} />
              ))}
            </div>
          </div>
        </section>
        {/* <!--/Events--> */}
        <DownloadLinks />
      </div>
      <Footer />
    </div>
  );
};

export default Event;