import React from 'react'
import Header from '../components/Header'
import MessagesComponent from '../components/Messages/MessagesComponent'
import AppWrapper from '../components/AppWrapper';
import Sidebar from '../components/Sidebar/Sidebar'

const Messages = () => {
  return (
    <AppWrapper>
      <svg style={{width: '0', height: '0', position: 'absolute'}} aria-hidden="true" focusable="false">
        <linearGradient id="my-cool-gradient" x2="1" y2="0">
        <stop offset="0%" stopColor="#e25867" />
        <stop offset="100%" stopColor="#e88969" />
      </linearGradient>
      </svg>

      <Header />
      <div className="page__wrapper page__bg page__bg--blur feed-page">
        <div className="page__feed">
        <Sidebar />
        <MessagesComponent />
        </div>
      </div>
    </AppWrapper>
  )
}

export default Messages
