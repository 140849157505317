import React, { useState } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { globalActions, authActions } from '../../redux/actions'

import { ReactComponent as ArrowIcon } from '../../imgs/icons/i-arrow_left.svg'
import { ReactComponent as ViewIcon } from '../../imgs/icons/i-view.svg'
import { ReactComponent as HideIcon } from '../../imgs/icons/i-hide.svg'
// import { ReactComponent as CheckboxIcon } from '../../imgs/icons/i-hide.svg'

import Popup from '../Popup'
// import Checkbox from '../Checkbox'
import RegisterStepOne from './RegisterStepOne'
import RegisterStepTwo from './RegisterStepTwo'
import RegisterStepThree from './RegisterStepThree'
import RegisterStepFour from './RegisterStepFour'
import RegisterStepFive from './RegisterStepFive'

// @todo add Custom checkboxes

const RegisterPopup = () => {

  const showRegisterModal = useSelector(state => state.globalReducer.showRegisterModal)
  const step = useSelector(state => state.authReducer.step)
  const dispatch = useDispatch()

	// const setStep = (state) => state <= 0 && state >= 4 ? _setStep(state) : console.error('Steps index should be in range from 0 to 4')

	// const [values, setValues] = useState({ 
	// 	email: '',
	// 	password: '',
	// 	user_name: '',
	// 	full_name: null,
	// 	avatar: null,
	// 	bio: null,
	// 	country: null
	//  })

	const steps = [
		<RegisterStepOne />,
		<RegisterStepTwo />,
		<RegisterStepThree />,
		<RegisterStepFour />, 
		<RegisterStepFive />
	]

	const handlePopupClose = e => {
    dispatch(globalActions.toggleRegisterModal(false, false))
  }

  return (
    <Popup open={showRegisterModal} hideArrow handleClose={handlePopupClose}>
      <div className="popup__block popup__signup">
					{ steps[step] }
			</div>
    </Popup>
    
  )
}

export default RegisterPopup
