export const ROUTES = {
  LANDING: '/',
  HOME: '/login',
  EVENTS: '/events',
  EVENT: '/event/:id',
  BUY_TICKET: '/buy-ticket/:id',
  CONTACT_US: '/contact-us',
  FEED: '/feed',
  CHATS: '/messages',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  USER: '/users/:id',
  POST: '/posts/:post_id',
  SEARCH: '/search',
  RATINGS: '/ratings',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  BLOCKED: '/blocked',
}