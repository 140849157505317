import React, { useState }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions, authActions } from '../../redux/actions'

import { config } from '../../config'
import { ReactComponent as ArrowIcon } from '../../imgs/icons/i-arrow_left.svg'

const RegisterStepThree = () => {
  const user = useSelector(state => state.authReducer.user)
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState()
	const [isFilePicked, setIsFilePicked] = useState(false)

  const stepForward = () => {
    dispatch(authActions.stepAdd())
  }

  const stepBackward = () => {
    dispatch(authActions.stepDecrement())
  }

  const changeHandler = (event) => {
		// setSelectedFile()
    dispatch(profileActions.uploadAvatar(event.target.files[0], user, true))
		setIsFilePicked(true)
    // setValues(prevValues => ({ ...prevValues, avatar: selectedFile }))
	};
  
  console.log(selectedFile)
  
  const handleSubmit = e => {
    e.preventDefault()
    stepForward()
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="signup__step signup__step--3">
        <div className="popup__btns popup__btns--back">
          <button className="btns btn-prevstep" data-target=".signup__step--2">
            <ArrowIcon onClick={stepBackward} className="icons svg i40x40" />
          </button>
        </div>
        <div className="popup__btns popup__btns--next">
          <button onClick={stepForward} className="btns popup__btns btn-nextstep" data-target=".signup__step--4">skip</button>
        </div>
        <div className="popup__header">
          {/* <figure className="signup__image">
            { user.avatar_url ? <img className="signup__avatar" src={config.REACT_APP_IMAGE_URL + user.avatar_url} /> : <i className="icons bg i90x90 i-smile_wink"></i> }
          </figure> */}
          
        </div>
        <div className="popup__content">
          <div className="form__row form__row--upload">
            
            <label className="form__label--upload" htmlFor="avatar">
                { user.avatar_url ? 
                  <figure style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + user.avatar_url})` }} className="signup__image"></figure> :
                  <figure className="signup__image"><i className="icons bg i90x90 i-smile_wink"></i></figure>
                }
              <input onChange={changeHandler} required name="avatar" className="form__upload" type="file" id="avatar" accept="image/png, image/jpeg, image/jpg" />
              Tap me to add your profile image</label>
          </div>
          <div className="signup__name">
            @{user.user_name}
          </div>
          <div className="signup__location">
            {user.country}
          </div>
          <div className="form__row form__row--submit">
            <button onClick={stepForward} className="btns btn-grdnt btn-submit btn-nextstep" data-target=".signup__step--4">NEXT</button>
          </div>	
        </div>
      </div>
    </form>
  ) 
}

export default RegisterStepThree
