import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {config} from '../config';

const EventItem = props => {
  const {event} = props;
  return (
    <div className="events__item">
      <figure className="event__image">
        <Link className="image__link" to={`/event/${event.id}`}>
          <img
            src={`${config.REACT_APP_IMAGE_URL}${event.image_url}` || ''}
            alt={event.title}
          />
        </Link>
        <span className="event__price">{`${event.price} EUR`}</span>
      </figure>
      <div className="event__block">
        <div className="event__date">
          <span>{moment(event.start_date).format('MMM')}</span>
          {moment(event.start_date).format('DD')}
        </div>
        <div className="event__text">
          <h2 className="event__title">
            <Link className="event__link" to={`/event/${event.id}`}>
              {event.title}
            </Link>
          </h2>
          <div className="event__location">{event.location}</div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;