import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { profileActions } from '../../redux/actions'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import { config } from '../../config'
import emojiImage from '../../imgs/emoji_small.png'
import { ReactComponent as VerifiedIcon } from '../../imgs/verified.svg'

// @todo profile top
const ProfileTop = () => {
  const meta = useSelector(state => state.profileReducer.ratingUsersMeta)
  const ratings = useSelector(state => state.profileReducer.ratingUsers)
  const currentUser = useSelector(state => state.authReducer.user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(profileActions.getRatings(meta))
  }, [])

  // console.log(ratings)

  const handleFollow = id => {
    dispatch(profileActions.follow(id))
  }

  const handleUnfollow = id => {
    dispatch(profileActions.unfollow(id))
  }

  const fetchData = () => {
    dispatch(profileActions.getRatings({...meta, page: meta.page + 1}))
  }

  return (
    <div className="search">
      <div className="search__container" id="searchContainer">
        
          <div className="search__header">
            <h1 className="search__headline">Leaderboard</h1>
          </div>
          <div className="search__content" id="searchContent">
          <InfiniteScroll next={fetchData} hasMore={meta.page < meta.total_pages} dataLength={ratings.length} scrollableTarget="searchContent" className="search__inifinite">

            { ratings.length ? ratings.map((user, i) => 
              <div className="search__user">
                <Link to={`/users/${user.id}`}>
                  <div className="search__user-info">
                    <div style={{ backgroundImage: `url(${user.avatar_thumb_url ? config.REACT_APP_IMAGE_URL + user.avatar_thumb_url : emojiImage})` }} className="search__user-avatar"></div>
                    <h4 className="search__username">{i + 1}. {user.user_name || 'unknown'} {user.verified && <VerifiedIcon className="icons i22x22 svg" />}</h4>
                  </div>
                </Link>
                  <div className="user-points"><strong>{user.points}</strong><span>points</span></div>
                {/* <div className="points-wrapper">
                  {
                   currentUser.id !== user.id && <>
                      {  user.is_followed ?  <button onClick={() => handleUnfollow(user.id)}className="follow-btn unfollow-btn">unfollow</button> : <button onClick={() => handleFollow(user.id)}className="follow-btn">follow</button> }
                    </>
                  }
                </div> */}
                
              </div>
              
            ) : <p className="search__fail">No such user</p>}
            </InfiniteScroll>
          </div>
        </div>
      </div>
  )
}

export default ProfileTop
