export const EventsInitialState = {
  events: [],
  upcoming_events: [],
  event: null,
  error: '',
};

export const AuthInitialState = {
  user: null,
  error: '',
  step: 0,
};

export const GlobalInitialState = {
  showLoginModal: false,
  showRegisterModal: false,
  reportModal: {
    show: false,
    reportUserId: null
  },
  successPopup: {
    show: false,
    message: ''
  },
  showBuyTicketButton: true,
  successMessage: '',
  error: '',
};

export const PostsInitialState = {
  posts: [],
  post: null,
  error: '',
  meta: {
    page: 1,
    perPage: 5,
    total_pages: null,
    search: '',
    tab: 'following',
  },
  userPosts: [],
  myPosts: [],
  myPostsMeta: {
    page: 1,
    perPage: 12,
    total_pages: null,
  },
  userPostsMeta: {
    page: 1,
    perPage: 12,
    total_pages: null,
    id: 0,
  },
};

export const ProfileInitialState = {
  users: [],
  user: null,
  error: '',
  meta: {
    page: 1,
    perPage: 4,
    total_pages: null,
    search: '',
  },
  ratingUsers: [],
  ratingUsersMeta: {
      page: 1,
      perPage: 10,
    },
  ratings: [],
  followers: [],
  following: [],
  blackList: [],
  donations: [],
  currentUser: null,
};

export const MESSAGING_STORE = {
  chats: [],
  chats_count: 0,
  chat: {},
  messages: [],
  message: {},
  messages_length: 0,
  badge_count: 0,
  showMessagePopup: false,
  cable: null,
  meta: {
    page: 1,
    perPage: 10,
    search: '',
  }
}

export const PaymentsInitialState = {
  isProcessing: false,
  paymentIntent: null,
  donateModal: {},
  donateModalVisible: false,
  paymentMessage: ''
}