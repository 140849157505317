import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { profileActions, postsActions, paymentActions, messagingActions } from '../../redux/actions'
import { Link, useParams, useHistory } from 'react-router-dom'
import { config } from '../../config'

import './Profile.css'
import DonatePopup from './DonatePopup'
import ProfilePosts from './ProfilePosts'

import emojiImage from '../../imgs/emoji_small.png'
import { ReactComponent as VerifiedIcon } from '../../imgs/verified.svg'
import MessagePopup from '../Messages/MessagePopup'

// @todo post detail

const ProfileComponent = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const user = useSelector(state => state.profileReducer.user)
  const authUser = useSelector(state => state.authReducer.user)
  const posts = useSelector(state => state.postsReducer)
  const [open, setOpen] = useState(false)
  const history = useHistory()

  useEffect(() => {
    console.log(id, authUser?.id)
    if(id == authUser?.id) {
      history.push('/profile')
    }
  }, [])

  useEffect(() => {
   dispatch(profileActions.getUserDetails(id))
  //  dispatch(postsActions.getUserPosts(posts.userPostsMeta, id))
  }, [id])

  const getNumberMask = (count) => {
    let str = ''
    if(count / 10e5 >= 1) str = `${Math.floor(count/10e5)} m`
    else if(count/10e2 >= 1) str = `${Math.floor(count/10e2)} k`
    else return str = `${count}`
    return str;
  }

  const sendReport = () => {
    const reportable = {id: user?.id, body: 'his name is too long and should not fit screen noooooo'};
    if(user){
      dispatch(profileActions.reportUser(reportable))
    } else {
      alert('no user lmao')
    }
  }

  const handleFollow = () => {
    dispatch(profileActions.follow(user.id))
  }

  const handleUnfollow = () => {
    dispatch(profileActions.unfollow(user.id))
  }

  const handleOpen = (e) => {
    dispatch(paymentActions.toggleDonateModal(user))
  }
  
  const handleMessage = id => {
    dispatch(messagingActions.toggleMessagePopup())

    dispatch(messagingActions.createChat(id))
  }

  // const redirectToDetail = id => {
  //   history.push(`/posts/${id}`)
  // }


  // useEffect(() => {}, [user])
  let img = user ? user.avatar_url : null;

  return user ? (
  <div className="page__content content__blur content__flex profile-page">
   <div className="profile" id="profilePosts">
    <div className="user__info">
      <div className="user">
        <figure className="user__image avatar">
          <img className="image" src={`${img ? config.REACT_APP_IMAGE_URL + img : emojiImage}`} alt="Avatar" />
        </figure>
        <div className="user__fullname">
          {user.full_name}
        </div>
        <div className="user__username">
          @{user.user_name} {user.verified && <VerifiedIcon className="icons i22x22 svg" />}
        </div>
        <Link to="/ratings">
          <div className="user__rate">
            {user.user_position > 0 ? user.user_position + 'th' : 'unranked' }
          </div>
        </Link> 
        <div className="profile__actions">
          { user.is_followed ? <button className="follow-btn followed" onClick={handleUnfollow}>FOLLOWING</button> : <button className="follow-btn" onClick={handleFollow}>FOLLOW</button> }
          {user.verified && <button onClick={handleOpen} className="donate-btn">DONATE</button> }
          <button className="message-btn" onClick={() => handleMessage(user.id)}>MESSAGE</button>
        </div>
          
        <div className="user_stats">
          <div className="stat">
            <span className="counter">{user.followed_users_count}</span>
            <span className="text">Following</span>
          </div>
          <div className="stat">
            <span className="counter">{getNumberMask(user.likes_count)}</span>
            <span className="text">Laughs</span>
          </div>
          <div className="stat">
            <span className="counter">{user.followers_count}</span>
            <span className="text">Followers</span>
          </div>
        </div>
      </div>
    </div>

    {/* <div className="user__posts">
      <div className="profile__posts">
          {posts.posts ? posts.posts.map(post => {
            let img = post.attachments[0].thumb_url
            return img ? (
              <div onClick={() => redirectToDetail(post.id)} className="profile__post" style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL}${img})`}}></div>
            ) : (
               <div onClick={() => redirectToDetail(post.id)} className="profile__post" style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL}${post.attachments[0].attachment_url})`}}></div>
            )
           }) : null }
        </div>
      </div> */}

      <ProfilePosts id={id} />

    </div>
    <MessagePopup id={id} />
    <DonatePopup open={open} user={user} />
  </div>
  ) : <h1>Loading</h1>
}

export default ProfileComponent