import React, { lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ROUTES } from '../constants/ROUTES'

import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import PageWrapper from '../components/PageWrapper';

import Home from '../pages/Home';
import Feed from '../pages/Feed';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import Messages from '../pages/Messages';
import MyProfile from '../pages/MyProfile';
import PostDetails from '../pages/PostDetails';
import Search from '../pages/Search';
import Ratings from '../pages/Ratings';
import Terms from '../pages/Terms';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Landing from '../pages/Landing';
import BuyTicket from '../containers/BuyTicket';
import ContactUs from '../containers/ContactUs';
import Event from '../containers/Event';
import Events from '../containers/Events';

const Routes = () => {
  return (
      <PageWrapper>
        <Switch>
          <PublicRoute exact path={ROUTES.HOME} component={Home} />
          <PrivateRoute exact path={ROUTES.FEED} component={Feed} />
          <PrivateRoute exact path={ROUTES.USER} component={Profile} />
          <PrivateRoute exact path={ROUTES.SETTINGS} component={Settings} />
          <PrivateRoute exact path={ROUTES.PROFILE} component={MyProfile} />
          <PrivateRoute exact path={ROUTES.POST} component={PostDetails} />
          <PrivateRoute exact path={ROUTES.CHATS} component={Messages} />
          <PrivateRoute exact path={ROUTES.SEARCH} component={Search} />
          <PrivateRoute exact path={ROUTES.RATINGS} component={Ratings} />
          <PublicRoute redirectable={false} exact path={ROUTES.LANDING} component={Landing} />
          <Route exact path={ROUTES.BUY_TICKET} component={BuyTicket}  />
          <Route exact path={ROUTES.CONTACT_US} component={ContactUs}  />
          <Route exact path={ROUTES.EVENT} component={Event}  />
          <Route exact path={ROUTES.EVENTS} component={Events}  />
          <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={ROUTES.TERMS_OF_USE} component={Terms} />
        </Switch>
      </PageWrapper>
  )
}

export default Routes
