import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {config} from '../config';

const EventItem = props => {
  const {event} = props;
  return (
    <div className="">
      <figure className="event__image">
        <img
          src={`${config.REACT_APP_IMAGE_URL}${event.image_url}` || ''}
          alt={event.title}
        />
      </figure>
      <Link className="event__text" to={`event/${event.id}`}>
        <div className="event__date">
          {moment(event.start_date).format('ddd, MMM D, H:mm A')}
          {/* Sun, Oct 4, 8:00 PM */}
        </div>
        <div className="event__location">{event.location}</div>
        <h2 className="event__title">{event.title}</h2>
      </Link>
    </div>
  );
};

export default EventItem;