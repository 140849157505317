import React from 'react'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../redux/actions'

import { ReactComponent as ArrowIcon } from '../../imgs/icons/i-arrow_left.svg'
import { ReactComponent as ViewIcon } from '../../imgs/icons/i-view.svg'
import { ReactComponent as HideIcon } from '../../imgs/icons/i-hide.svg'

const RegisterStepOne = () => {
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const error = useSelector(state => state.authReducer.error)
  
  const onSubmit = data => {
    
    if(data.email == data.email2) {
      dispatch(authActions.signUp({ email: data.email, password: data.password }))
      // stepForward()
    }
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="signup__step signup__step--1 ">
      <div className="popup__header">
        <div className="popup__title">Create an account</div>
      </div>
      <div className="popup__content">
        <div className="form__row">
          <input type="email" className="form__input" placeholder="Email" { ...register('email', { required: true}) } />
        </div>
        <div className="form__row">
          <input type="email" className="form__input" placeholder="Repeat email" { ...register('email2', { required: true}) } />
        </div>
        <div className="form__row form__row--password form__row--icon">
          <input type="password" className="form__input" placeholder="Password" { ...register('password', { required: true, pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/ }) } />
          {/* <button type="button" className="btns btn-showpass">
            <ViewIcon className="icons svg i40x40" />
          </button>
          <button type="button" className="btns btn-hidepass">	
            <HideIcon className="icons svg i40x40" />
          </button> */}
        </div>
          { errors.password && <p className="form-error">this password is too weak</p> }
        <div className="form__row form__row--hint">
          Must be minimum of 6 characters long, with at least one capital letter and one number
        </div>
        <div className="form__row form__row--checkbox">
          <input className="form__checkbox" type="checkbox" id="policy" />
          {/* <label className="form__label form__label--checkbox" htmlFor="policy"> */}
            {/* <Checkbox checked={checked} onChange={handleCheck} /> */}
            {/* I agree with <a href="#">Terms</a> and <a href="#">Privacy Policy</a></label> */}
        </div>
        <div className="form__row form__row--submit">
          <button type="submit" className="btns btn-grdnt btn-submit btn-nextstep" data-target=".signup__step--2">NEXT</button>
          <p className="error">Email is already registered or password is incorrect</p>
        </div>	
      </div>
    </div>
    </form>
  )
}

export default RegisterStepOne
