import {filterBlackList} from '../../helpers';
import {INITIAL_STATE, POSTS_CONSTS} from '../constants';

const postsReducer = (state = INITIAL_STATE.PostsInitialState, action) => {
  const {type, payload} = action || {};
  const {
    meta,
    posts = [],
    post,
    error,
    total_pages,
    id = 0,
    likes_count,
    replyId,
    reply,
    userPosts,
  } = payload || {};
  switch (type) {
    case POSTS_CONSTS.POSTS_ACTIONS.GET_POSTS: {
      const newMeta = meta || state.meta;
      const newPosts = newMeta.page === 1 ? []:state.posts;
      return {...state, meta: newMeta, posts: newPosts};
    }
    case POSTS_CONSTS.POSTS_ACTIONS.GET_POSTS_SUCCESS: {
      const {page} = state.meta || {};
      const newPosts = page === 1 ? posts : [...state.posts, ...posts];
      return {
        ...state,
        posts: newPosts,
        meta: {
          ...state.meta, 
          total_pages: total_pages
        }
      };
    }
    case POSTS_CONSTS.POSTS_ACTIONS.GET_POST_SUCCESS: {
      const newPosts = [...state.posts];
      const i = newPosts.findIndex((el) => el.id === post?.id);
      if (i !== -1 && post) newPosts[i] = post;
      return {
        ...state,
        post: post || null,
        posts: newPosts,
      };
    }
    case POSTS_CONSTS.POSTS_ACTIONS.GET_USER_POSTS:
      return {
        ...state,
        userPostsMeta: meta,
      }
    case POSTS_CONSTS.POSTS_ACTIONS.GET_USER_POSTS_SUCCESS: {
      const newPosts = state.userPostsMeta.page === 1? userPosts:[...state.userPosts, ...userPosts]
      return {
        ...state,
        userPosts: newPosts,
        userPostsMeta: {
          ...state.userPostsMeta,
          total_pages
        }
      }
      
    }
    case POSTS_CONSTS.POSTS_ACTIONS.FOLLOW_SUCCESS: {
      const newPost = state.post;
      if (newPost && newPost.user?.id === id) newPost.is_followed = true;
      const newPosts = [...state.posts];
      newPosts.forEach((item, index) => {
        if (item.user?.id === id) newPosts[index].is_followed = true;
      });
      // user.forEach((item, index) => {
      //   if (item.user?.id === id) newPosts[index].is_followed = true;
      // });
      return {
        ...state,
        post: newPost,
        posts: newPosts,
      };

    }
    case POSTS_CONSTS.POSTS_ACTIONS.UNFOLLOW_SUCCESS: {
      const newPost = state.post;
      if (newPost && newPost.user?.id === id) newPost.is_followed = false;
      const newPosts = [...state.posts];
      newPosts.forEach((item, index) => {
        if (item.user?.id === id) newPosts[index].is_followed = false;
      });
      return {
        ...state,
        post: newPost,
        posts: newPosts,
      };
    }

    case POSTS_CONSTS.POSTS_ACTIONS.LIKE_POST_SUCCESS: {
      const newPosts = [...state.posts];
      const i = newPosts.findIndex((el) => el.id === id);
      newPosts[i].is_liked = !newPosts[i].is_liked;
      newPosts[i].likes_count = likes_count || 0;
      const newPost = state.post || null;
      if (newPost) {
        newPost.is_liked = !newPost.is_liked;
        newPost.likes_count = likes_count || 0;
      }
      return {
        ...state,
        post: newPost,
        posts: newPosts,
      };
    }
    case POSTS_CONSTS.POSTS_ACTIONS.LIKE_REPLY_SUCCESS: {
      const newPosts = [...state.posts];
      const postIndex = newPosts.findIndex(el => el.id === reply.post_id);

      const newReplies = newPosts[postIndex].replies;
      console.log({post: newPosts[postIndex]})
      const i = newReplies.findIndex((el) => el.id === reply.id);
      console.log({i})
      // const pi = newPostsReplies.findIndex((el) => el.id === id);
      newReplies[i].is_liked = !newReplies[i].is_liked;
      newReplies[i].likes_count = newReplies[i].is_liked ? newReplies[i].likes_count + 1 : newReplies[i].likes_count - 1;
      newPosts[postIndex].replies = newReplies;
      return {
        ...state,
        posts: newPosts,
        post: {
          ...state.post,
          replies: newReplies
        }
      }
    }
    case POSTS_CONSTS.POSTS_ACTIONS.SEND_VIEW_SUCCESS: {
      const newPosts = [...state.posts];
      const newPost = state.post;
      const i = newPosts.findIndex((el) => el.id === id);
      newPosts[i].views_count += 1;
      if (newPost) newPost.views_count += 1;
      return {...state, post: newPost, posts: newPosts};
    }
    case POSTS_CONSTS.POSTS_ACTIONS.DELETE_POST_SUCCESS: {
      const newPosts = [...state.posts].filter((el) => el.id !== id);
      return {
        ...state,
        posts: newPosts,
      };
    }
    case POSTS_CONSTS.POSTS_ACTIONS.LIKE_REPLY_ERROR:
    case POSTS_CONSTS.POSTS_ACTIONS.GET_POST_ERROR:
    case POSTS_CONSTS.POSTS_ACTIONS.GET_POSTS_ERROR:
      return {
        ...state,
        error: error || '',
      };
    case POSTS_CONSTS.POSTS_ACTIONS.CREATE_REPLY_SUCCESS: {
      const newPosts = [...state.posts];
      const newPost = {...state.post};
      const post_id = reply?.post_id || 0;
      // console.log(reply)
      // console.log(post_id)
      if (newPost && newPost.id === post_id && reply) {
        newPost.replies = newPost.replies
          ? ([reply, ...newPost.replies])
          : [reply];
        newPost.replies_count += 1;
      }
      const postIndex = newPosts.findIndex((el) => el?.id === post_id);
      if (postIndex !== -1 && reply) {
        const replies = newPosts[postIndex].replies || [];
        replies.unshift(reply);
        newPosts[postIndex].replies = replies;
        newPosts[postIndex].replies_count += 1;
      }
      return {...state, post: newPost, posts: newPosts};
      // return {...state, posts: newPosts};
    }
    case POSTS_CONSTS.POSTS_ACTIONS.DELETE_REPLY_SUCCESS: {
      const newPosts = [...state.posts];
      const newPost = state.post;
      if (newPost && newPost?.id === id)
      {
        newPost.replies = newPost.replies?.filter((el) => el.id !== replyId);
        console.log(12121)
      }
      const postIndex = newPosts.findIndex((el) => el?.id === id);
      const replyIndex =
        newPosts[postIndex]?.replies?.findIndex((el) => el.id === replyId)
      if (replyIndex !== -1) {
        newPosts[postIndex]?.replies?.splice(replyIndex, 1);
        newPosts[postIndex].replies_count -= 1;
      }
      return {...state, post: newPost, posts: newPosts};
    }
    case POSTS_CONSTS.POSTS_ACTIONS.BLOCK_SUCCESS: {
      const blackList = [Number(id)];
      const newPosts = filterBlackList({
        posts: state.posts,
        blackList,
        blockedMe: [],
        skipComments: false,
      });
      let newPost = state.post;
      if (newPost)
        newPost =
          (filterBlackList({
            posts: [newPost],
            blackList,
            blockedMe: [],
            skipComments: false,
          })?.[0]) || null;
      const newUserPosts = filterBlackList({
        posts: state.userPosts,
        blackList,
        blockedMe: [],
        skipComments: false,
      });
      return {
        ...state,
        post: newPost,
        userPosts: newUserPosts,
        posts: newPosts,
      };
    }
    case POSTS_CONSTS.POSTS_ACTIONS.LOGOUT_SUCCESS:
      return INITIAL_STATE.PostsInitialState
    default:
      return state;
  }
};

export {postsReducer};