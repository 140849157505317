import React, { useEffect} from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getToken, getUser } from '../services/LocalStorage'
import { isMobile, isIOS } from 'react-device-detect'


const PublicRoute = ({ redirectable = true, ...props }) => {
  const token = getToken()
  const user = getUser()

    if(redirectable) {
      if(isMobile) {
        window.location.replace(`ilaugh://`)
        // window.location.replace(`ilaugh://`)
        // window.location.replace(`intent://example.com#Intent;scheme=http;package=com.example.mypackage;end`)
      if(isIOS) {
      setTimeout(function () { window.location = "https://apps.apple.com/us/app/ilaugh-app/id1516551814"; }, 100);
      } else {
        setTimeout(function () { window.location = "https://play.google.com/store/apps/details?id=com.ilaugh&hl=en&gl=US"; }, 100); 
      }

        // window.location.reload();
    }
    }
  
  return (
    token && user?.is_finished_signup  ? <Redirect to="/feed" /> : <Route {...props } />
  )
}

export default PublicRoute
