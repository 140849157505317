import React from 'react'
import Popup from '../Popup'
import { useSelector, useDispatch } from 'react-redux'
import { messagingActions } from '../../redux/actions'
import { useForm } from 'react-hook-form'

const MessagePopup = ({ id }) => {
  const open = useSelector(state => state.messagingReducer.showMessagePopup)
  const { register, handleSubmit, formState: { errors }, reset } = useForm()

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(messagingActions.toggleMessagePopup())
  }

  const onSubmit = data => {
    // console.log(data)
    dispatch(messagingActions.sendMessageFromModal(data.message, id))
    reset({ message: '' })
  }

  const handleEnter = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  return (
    <Popup open={open} handleClose={handleClose} className="popup__block message-popup">
      <h2 className="message-popup__headline">Send a message</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea { ...register('message', { required: true }) } className="form__input" placeholder="Enter a message" />
        { errors.message && <p className="error">Message is required</p> }
        <input className="message-popup__submit" value="SEND" type="submit" />
      </form>
    </Popup>
  )
}

export default MessagePopup
