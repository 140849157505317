import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux'
import { profileActions } from '../../redux/actions'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom'

import './Search.css'
import { config } from '../../config'
import emojiImage from '../../imgs/emoji_small.png'
import { ReactComponent as VerifiedIcon } from '../../imgs/verified.svg'

const SearchComponent = () => {
  const meta = useSelector(state => state.profileReducer.usersMeta)
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState()
  const users = useSelector(state => state.profileReducer.users)
  const currentUser = useSelector(state => state.authReducer.user)

  const dispatch = useDispatch()

  const handleSearch = _.debounce((search, page) => dispatch(profileActions.getUsers({page: 1, perPage: 24, search: search})),
        500,
  );

  const handleChange = e => {
    // console.log(e)
    setSearchValue(e.target.value)
    handleSearch(e.target.value)
  }

  const handleFollow = id => {
    dispatch(profileActions.follow(id))
  }

  const handleUnfollow = id => {
    dispatch(profileActions.unfollow(id))
  }

  useEffect(() => {
    handleSearch(searchValue)
  }, [])

  const fetchData = () => {
    dispatch(profileActions.getUsers({page: meta.page + 1, perPage: 24, search: searchValue}))
  }
  
  // useEffect(() => console.log(meta), [meta])

  return (
    <div className="search">
      <div className="search__container" id="searchContainer">
        
          <div className="search__header">
            <h1 className="search__headline">Find friends</h1>
            <input onChange={handleChange} value={searchValue} className="search__input" type="text" placeholder="search" />
          </div>
          <div className="search__content" id="searchContent">
          <InfiniteScroll next={fetchData} hasMore={meta?.total_pages > meta?.page} dataLength={users.length} scrollableTarget="searchContent" className="search__inifinite">

            { users.length ? users.map(user => 
              <div className="search__user">
                <Link to={`/users/${user.id}`}>
                  <div className="search__user-info">
                    <div style={{ backgroundImage: `url(${user.avatar_thumb_url ? config.REACT_APP_IMAGE_URL + user.avatar_thumb_url : emojiImage})` }} className="search__user-avatar"></div>
                    <h4 className="search__username">{user.user_name || 'unknown'} {user.verified && <VerifiedIcon className="icons i22x22 svg" />}</h4>
                  </div>
                </Link>
                {
                   currentUser.id !== user.id && <>
                      {  user.is_followed ?  <button onClick={() => handleUnfollow(user.id)}className="follow-btn unfollow-btn">unfollow</button> : <button onClick={() => handleFollow(user.id)}className="follow-btn">follow</button> }
                    </>
                  }
              </div>
              
            ) : <p className="search__fail">No such user</p>}
            </InfiniteScroll>
          </div>
        </div>
      </div>
  )
}

export default SearchComponent
