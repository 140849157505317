import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import { postsActions } from '../../redux/actions'

const PostCommentsForm = ({ id }) => {
  const { register, handleSubmit, formState: { errors, isSubmitSuccessful  }, reset } = useForm();
  const dispatch = useDispatch()

  const onSubmit = (data, e) => {
    // console.log(id)
    dispatch(postsActions.createReply(data.message, id))
    reset({ message: '' })
  }

  const handleEnter = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="comment__form">
      <textarea {...register("message", { required: true, maxLength: 200 })} onKeyPress={handleEnter} 
      className="comment__text-input" /> 
      {errors.message && <p className="form__error">Message is required, and should have less than 200 characters</p>}
      <input type="submit" className="comment__form-submit" value="Comment" />
    </form>
  )
}

export default PostCommentsForm