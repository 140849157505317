import React, { useEffect, useState }from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { postsActions, paymentActions } from '../../redux/actions'
import InfiniteScroll from 'react-infinite-scroll-component';

import './Posts.css'
import Post from './Post'
import PostSelect from './PostSelect';
import DonatePopup from '../Profile/DonatePopup';

const FeedPosts = () => {
  const dispatch = useDispatch()
  const { posts, meta } = useSelector(state => state.postsReducer)
  const [hasMore, setHasMore] = useState(true)
  
  useEffect(() => {
    dispatch(postsActions.getPosts(meta))
  }, [])

  useEffect(() => {
    setHasMore(meta.page < meta.total_pages)
  }, [meta.page, meta.total_pages])

  const fetchData = () => {
    dispatch(postsActions.getPosts({ ...meta, page: meta.page + 1 }))
  }

  

  return posts ? (
    <div className="posts">         
          <div className="posts-list" id="postList">
            <InfiniteScroll next={fetchData} hasMore={hasMore} className="post__scroller" dataLength={posts.length} scrollableTarget="postList" >
              { posts.length ? posts.map((post, i) => <Post key={i} post={post} /> ) : <p>Nothing yet here</p> }
            </InfiniteScroll>
          </div>

          <DonatePopup />
    </div>
  ) : <h2>Loading...</h2>
}

export default FeedPosts
