import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { postsActions } from '../../redux/actions'
import Post from './Post'
import DonatePopup from '../Profile/DonatePopup'

import { isMobile, isIOS } from 'react-device-detect';


// @todo
const PostDetail = () => {
  const { post_id } = useParams()
  const { post } = useSelector(state => state.postsReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if(isMobile) {
      if(isIOS) {
      setTimeout(function () { window.location = "https://apps.apple.com/us/app/ilaugh-app/id1516551814"; }, 100);
      } else {
        setTimeout(function () { window.location = "https://play.google.com/store/apps/details?id=com.ilaugh&hl=en&gl=US"; }, 100); 
      }
      window.location.href = `ilaugh://details/${post_id}`;
    }
    dispatch(postsActions.getPost(post_id))
  }, [])

  

  return post?.id == post_id ? (
    <div className="post__detail">
      <Post post={post} />
      <DonatePopup />
    </div>
  ) : <h3>Loading</h3>
}

export default PostDetail
