import {AUTH_CONSTS} from '../constants';

export const isAuthenticated = () => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.IS_AUTHENTICATED,
  payload: {},
});

export const login = (email, password,) => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.LOGIN,
  payload: {email, password},
});

export const loginSuccess = (user) => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.LOGIN_SUCCESS,
  payload: {user},
});

export const loginError = (error) => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.LOGIN_ERROR,
  payload: {error},
});

export const signUp = (formData) => {
  return {
    type: AUTH_CONSTS.AUTH_ACTIONS.SIGN_UP,
    payload: {formData},
  };
};

export const signUpSuccess = (user) => {
  return {
    type: AUTH_CONSTS.AUTH_ACTIONS.SIGN_UP_SUCCESS,
    payload: {user},
  };
};

export const signUpError = (error) => {
  return {
    type: AUTH_CONSTS.AUTH_ACTIONS.SIGN_UP_ERROR,
    payload: {error},
  };
};

export const logout = () => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.LOGOUT,
  payload: {},
});

export const logoutSuccess = () => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.LOGOUT_SUCCESS,
  payload: {user: null},
});

export const logoutError = (error) => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.LOGOUT_ERROR,
  payload: {error},
});

export const stepSet = (step) => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.STEP_SET,
  payload: {step}
})

export const stepAdd = () => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.STEP_ADD,
  payload: {}
})

export const stepDecrement = () => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.STEP_DECREMENT,
  payload: {}
})


export const unblockSuccess = (id) => ({
  type: AUTH_CONSTS.AUTH_ACTIONS.UNBLOCK_SUCCESS,
  payload: {id},
});
