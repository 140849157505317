import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions, authActions } from '../../redux/actions'
import { useForm } from "react-hook-form";
import { CountryDropdown } from 'react-country-region-selector';

import { ReactComponent as ArrowIcon } from '../../imgs/icons/i-arrow_left.svg'

const RegisterStepTwo = ({ }) => {
  const user = useSelector(state => state.authReducer.user)
  const [country, setCountry] = useState(user?.country)
  const dispatch = useDispatch()
  // const user = 
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onSubmit' });

  const onSubmit = data => {
      dispatch(profileActions.updateProfile({
        ...user,
        user_name: data.user_name,
        country: country ? country : null
      }, true))
      // stepForward()
  }

  return user ? (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="signup__step signup__step--2">
        <div className="popup__header">
          <div className="popup__title">Let’s set up your profile</div>
          <div className="popup__subtitle">
            <i className="icons bg i22x22 i-smile_wink"></i>What is your nickname?</div>
        </div>
        <div className="popup__content">
          <div className="form__row">
            <input defaultValue={user.user_name} { ...register('user_name', { required: true }) } type="text" className="form__input" placeholder="Nickname" />
            {errors.user_name && <p className="form-error">username is required</p>}
            <CountryDropdown value={country} onChange={(val) => setCountry(val)} className="form__input country-selector" />
          </div>
          <div className="form__row form__row--submit">
            <button className="btns btn-grdnt btn-submit btn-nextstep" data-target=".signup__step--3">NEXT</button>
            <p className="error">Usrname is already used</p>

          </div>	
        </div>
      </div>
    </form>
  ) : null
}

export default RegisterStepTwo