import Routes from './router/index'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { config } from './config'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ConnectedRouter } from 'connected-react-router'
import { appHistory } from './services/HistoryConfig'
import './styles/index.css';

const App = () => {
  const stripePromise = loadStripe(config.STRIPE_API_KEY);
  return (
    <Provider store={store}>
      <ConnectedRouter history={appHistory} >
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
