import React from 'react';

const DownloadLinks = () => {
  return (
    <section className="download">
      <div className="site-width">
        <h3 className="section__subtitle">
          Let’s join the Worlds Laughs, Gigs & Giggles
        </h3>
        <h2 className="section__title">Download for iOS/Android</h2>
        <div className="app__links">
          <ul className="links__list">
            <li className="links__item">
              <a
                className="links__link appstore"
                target="_blank"
                href="https://apps.apple.com/ua/app/ilaugh-app/id1516551814"
              />
            </li>
            <li className="links__item">
              <a
                className="links__link googleplay"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ilaugh"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DownloadLinks;
