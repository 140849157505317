export const POSTS_ACTIONS = {
  GET_POSTS : 'GET_POSTS',
  GET_POSTS_SUCCESS : 'GET_POSTS_SUCCESS',
  GET_POSTS_ERROR : 'GET_POSTS_ERROR',
  GET_POST : 'GET_POST',
  GET_POST_SUCCESS : 'GET_POST_SUCCESS',
  GET_POST_ERROR : 'GET_POST_ERROR',
  GET_USER_POSTS : 'GET_USER_POSTS',
  GET_USER_POSTS_SUCCESS: 'GET_USER_POSTS_SUCCESS',
  GET_USER_POSTS_ERROR : 'GET_USER_POSTS_ERROR',
  FOLLOW_SUCCESS : 'FOLLOW_SUCCESS',
  UNFOLLOW_SUCCESS : 'UNFOLLOW_SUCCESS',
  LIKE_POST_SUCCESS : 'LIKE_POST_SUCCESS',
  LIKE_POST_ERROR : 'LIKE_POST_ERROR',
  LIKE_POST : 'LIKE_POST',
  REPORT_POST : 'REPORT_POST',
  REPORT_POST_SUCCESS : 'REPORT_POST_SUCCESS',
  REPORT_POST_ERROR : 'REPORT_POST_ERROR',
  SEND_VIEW : 'SEND_VIEW',
  SEND_VIEW_SUCCESS : 'SEND_VIEW_SUCCESS',
  DELETE_POST : 'DELETE_POST',
  DELETE_POST_SUCCESS : 'DELETE_POST_SUCCESS',
  CREATE_REPLY : 'CREATE_REPLY',
  CREATE_REPLY_SUCCESS : 'CREATE_REPLY_SUCCESS',
  CREATE_REPLY_ERROR : 'CREATE_REPLY_ERROR',
  LIKE_REPLY: 'LIKE_REPLY',
  LIKE_REPLY_SUCCESS : 'LIKE_REPLY_SUCCESS',
  LIKE_REPLY_ERROR : 'LIKE_REPLY_ERROR',
  DELETE_REPLY : 'DELETE_REPLY',
  DELETE_REPLY_SUCCESS : 'DELETE_REPLY_SUCCESS',
  DELETE_REPLY_ERROR : 'DELETE_REPLY_ERROR',
  REPORT_REPLY : 'REPORT_REPLY',
  REPORT_REPLY_SUCCESS : 'REPORT_REPLY_SUCCESS',
  REPORT_REPLY_ERROR : 'REPORT_REPLY_ERROR',
  BLOCK_SUCCESS : 'BLOCK_SUCCESS',
  GET_USER_POSTS : 'GET_USER_POSTS',
  GET_MY_POSTS : 'GET_MY_POSTS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
}