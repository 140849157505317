import React from 'react'

import MessagesList from './MessagesList'
import MessagesRooms from './MessagesRooms'

import './Messages.css'
import MessagesForm from './MessagesForm'

const MessagesComponent = () => {
  return (
     <div className="messages">
        <MessagesRooms />
      <div className="message-container">
        <MessagesList />
        <MessagesForm />
      </div>
    </div>
  )
}

export default MessagesComponent
