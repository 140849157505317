import React from 'react'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { profileActions, authActions } from '../../redux/actions'

import { ReactComponent as ArrowIcon } from '../../imgs/icons/i-arrow_left.svg'


const RegisterStepFour = ({ }) => {
  const user = useSelector(state => state.authReducer.user)
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onSubmit' });

  const stepForward = () => {
    dispatch(authActions.stepAdd())
  }

  const stepBackward = () => {
    dispatch(authActions.stepDecrement())
  }

  const onSubmit = data => {
    dispatch(profileActions.updateProfile({
        ...user,
        bio: data.bio ? data.bio : null,
        full_name: data.full_name ? data.full_name : null
      }, true))
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="signup__step signup__step--4">
        <div className="popup__btns popup__btns--back">
          <button className="btns btn-prevstep" data-target=".signup__step--2">
            <ArrowIcon onClick={stepBackward} className="icons svg i40x40" />
          </button>
        </div>
        <div className="popup__btns popup__btns--next">
          <button className="btns popup__btns btn-nextstep" data-target=".signup__step--5">skip</button>
        </div>
        <div className="popup__header">
          <div className="popup__title">You’re looking Super Cool <i className="icons bg i36x36 i-smile_wink"></i> </div>
        </div>
        <div className="popup__content">
          <div className="form__row">
            <input defaultValue={user.full_name} { ...register('full_name') } type="text" className="form__input full_name" placeholder="Enter your full name" />
            <textarea defaultValue={user.bio} { ...register('bio') } className="form__textarea" cols="30" rows="7" placeholder="add a short bio so new friends can check it out..."></textarea>
          </div>
          <div className="form__row form__row--submit">
            <button className="btns btn-grdnt btn-submit btn-nextstep" data-target=".signup__step--5">NEXT</button>
          </div>	
        </div>
      </div>
    </form>
  )
}

export default RegisterStepFour
