import { MESSAGING_CONSTS } from '../constants';

export const getChats = (meta) => {
  return {
    type: MESSAGING_CONSTS.GET_CHATS,
    payload: {meta},
  };
};

export const getChatsSuccess = (chats, total_pages) => {
  return {
    type: MESSAGING_CONSTS.GET_CHATS_SUCCESS,
    payload: {chats, total_pages},
  };
};

export const getChatsNextPageSuccess = (chats, total_pages) => {
  return {
    type: MESSAGING_CONSTS.GET_CHATS_NEXT_PAGE_SUCCESS,
    payload: {chats, total_pages},
  };
};

export const getChatsError = (error) => {
  return {
    type: MESSAGING_CONSTS.GET_CHATS_ERROR,
    payload: {error},
  };
};

export const getMessages = (messagesMeta) => {
  return {
    type: MESSAGING_CONSTS.GET_MESSAGES,
    payload: {messagesMeta}
  }
}

export const getMessagesSuccess = (messages, total_pages, date) => {
  return {
    type: MESSAGING_CONSTS.GET_MESSAGES_SUCCESS,
    payload: {messages, total_pages, date}
  }
}

export const getMessagesError = (error) => {
  return {
    type: MESSAGING_CONSTS.GET_MESSAGES_ERROR,
    payload: {error}
  }
}

export const createChat = (user_id) => {
  return {
    type: MESSAGING_CONSTS.CREATE_CHAT,
    payload: {user_id},
  };
};

export const createChatSuccess = (chat, messages) => {
  return {
    type: MESSAGING_CONSTS.CREATE_CHAT_SUCCESS,
    payload: {chat, messages},
  };
};

export const createChatError = (error) => {
  return {
    type: MESSAGING_CONSTS.CREATE_CHAT_ERROR,
    payload: {error},
  };
};

export const sendMessage = (message) => {
  return {
    type: MESSAGING_CONSTS.SEND_MESSAGE,
    payload: {message},
  };
};

export const sendMessageSuccess = (message) => {
  return {
    type: MESSAGING_CONSTS.SEND_MESSAGE_SUCCESS,
    payload: {message},
  };
};

export const sendMessageFromModal = (message, user_id) => {
  return {
    type: MESSAGING_CONSTS.SEND_MESSAGE_FROM_MODAL,
    payload: {message, user_id},
  };
};

export const sendMessageError = (error) => {
  return {
    type: MESSAGING_CONSTS.SEND_MESSAGE_ERROR,
    payload: {error},
  };
};

export const receivedMessage = (message) => {
  return {
    type: MESSAGING_CONSTS.RECEIVED_MESSAGE,
    payload: {message},
  };
};

export const deleteMessage = (message) => {
  return {
    type: MESSAGING_CONSTS.DELETE_MESSAGE,
    payload: {message},
  };
};

export const deleteMessageSuccess = (message) => {
  return {
    type: MESSAGING_CONSTS.DELETE_MESSAGE_SUCCESS,
    payload: {message},
  };
};

export const deleteMessageError = (error) => {
  return {
    type: MESSAGING_CONSTS.DELETE_MESSAGE_ERROR,
    payload: {error},
  };
};

export const clearChat = () => {
  return {
    type: MESSAGING_CONSTS.CLEAR_CHAT,
  };
};

export const receivedChat = (chat) => {
  return {
    type: MESSAGING_CONSTS.RECEIVED_CHAT,
    payload: {chat},
  };
};

export const updateChat = (chat) => {
  return {
    type: MESSAGING_CONSTS.UPDATE_CHAT,
    payload: {chat},
  };
};

export const setInitialState = () => {
  return {
    type: MESSAGING_CONSTS.SET_INITIAL_STATE,
  };
};

export const webSocketConnection = (cable) => {
  return {
    type: MESSAGING_CONSTS.WEB_SOCKET_CONNECTION,
    payload: {cable}
  }
}

export const toggleMessagePopup = () => {
  return {
    type: MESSAGING_CONSTS.TOGGLE_MESSAGE_POPUP,
    payload: {}
  }
}
