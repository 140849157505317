import { PAYMENTS_CONSTS, INITIAL_STATE } from '../constants';

export const paymentsReducer = (state = INITIAL_STATE.PaymentsInitialState, action) => { 
  const {type, payload} = action || {};
  const {
    payment,
    paymentIntent,
    donateModal,
    open,
    proccessing,
    user
  } = payload || {};

  switch (type) {
    case PAYMENTS_CONSTS.PAYMENTS_ACTIONS.DONATE_SUCCESS: {
      if(paymentIntent.status === 'succeeded') {
        return {
          ...state,
          paymentIntent: paymentIntent
        }
      }
    }
    case PAYMENTS_CONSTS.PAYMENTS_ACTIONS.TOGGLE_DONATE_MODAL: {
      if(user) {
        return {
          ...state,
          donateModalVisible: !state.donateModalVisible,
          donateModal: { user }
        }
      }

      return {
        ...state,
        donateModalVisible: !state.donateModalVisible,
      } 
    }
    case PAYMENTS_CONSTS.PAYMENTS_ACTIONS.TOGGLE_IS_PROCCESSING: {
      if(proccessing) {
        return {
          ...state,
          isProcessing: proccessing
        }
      }

      return {
        ...state,
        isProcessing: !state.isProcessing
      }
    }
    default:
      return state;
  }
}