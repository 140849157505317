import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, profileActions } from '../../redux/actions'

import Confetti from 'react-confetti'
import { ReactComponent as ArrowIcon } from '../../imgs/icons/i-arrow_left.svg'


const RegisterStepFive = ({  }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.authReducer.user)

  const stepBackward = () => {
    dispatch(authActions.stepDecrement())
  }

  const handleSubmit = e => {
    e.preventDefault()
    if(user.user_name) {
      dispatch(profileActions.updateProfile({
        ...user,
        is_finished_signup: true
      }, true))
    }
  }

  return (
    <>
    <Confetti className="confetti" />
    <form className="form" onSubmit={handleSubmit}>
      <div className="signup__step signup__step--5">
        <div className="popup__btns popup__btns--back">
          <button className="btns btn-prevstep" data-target=".signup__step--2">
            <ArrowIcon onClick={stepBackward} className="icons svg i40x40" />
          </button>
        </div>
        <div className="popup__header">
          <div className="popup__title">
            THAT’S IT ALL DONE
            <i className="icons bg i140x140 i-smile_wink"></i>
          </div>
          <div className="popup__subtitle">Now go and have some fun</div>
        </div>
        <div className="popup__content">
          <div className="form__row form__row--submit">
            <button className="btns btn-grdnt btn-submit">NEXT</button>
          </div>	
        </div>
      </div>
    </form>
    </>
  )
}

export default RegisterStepFive
