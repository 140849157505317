import React, { useEffect, useState }from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { postsActions } from '../../redux/actions'
import InfiniteScroll from 'react-infinite-scroll-component';
import { config } from '../../config'



const ProfilePosts = ({ id }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  // const { posts, meta } = useSelector(state => state.postsReducer)
  const [hasMore, setHasMore] = useState(true)
  const user = useSelector(state => state.profileReducer.user)
  const authUser = useSelector(state => state.authReducer.user)
  const {userPosts, userPostsMeta: meta}  = useSelector(state => state.postsReducer)
  
  useEffect(() => {
   dispatch(postsActions.getUserPosts({page: 1, perPage: 12}, id))
  }, [id])

  useEffect(() => {
    setHasMore(meta.page < meta.total_pages)
  }, [meta.page, meta.total_pages])

  const fetchData = () => {
    dispatch(postsActions.getUserPosts({ ...meta, page: meta.page + 1 }, id))
    console.log(1212210120120120)
  }

  const redirectToDetail = id => {
    history.push(`/posts/${id}`)
  }

  console.log(hasMore)

  return userPosts ? (
    <div className="user__posts">
      <div className="profile__posts">
      <InfiniteScroll next={fetchData} hasMore={hasMore} className="profile__scroller" dataLength={userPosts?.length} scrollableTarget="profilePosts" >
          {userPosts?.map(post => {
            let img = post.attachments[0].thumb_url
            return img ? (
              <div onClick={() => redirectToDetail(post.id)} className="profile__post" style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL}${img})`}}></div>
            ) : (
               <div onClick={() => redirectToDetail(post.id)} className="profile__post" style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL}${post.attachments[0].attachment_url})`}}></div>
            )
           }) || null }
        </InfiniteScroll>
        </div>
      </div>
  ) : <h2>Loading...</h2>
}

export default ProfilePosts
