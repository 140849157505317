
import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTES} from '../constants/ROUTES';

const LandingFooter = () => (
  <footer className="footer">
    <div className="site-width">
      <div className="footer__menu menu--horizontal">
        <ul className="menu__list">
          <li className="menu__item">
            <Link className="menu__link" to={ROUTES.TERMS_OF_USE}>
              Terms
            </Link>
          </li>
          <li className="menu__item">
            <Link className="menu__link" to={ROUTES.PRIVACY_POLICY}>
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer__copyrights">© 2020 Ilaugh</div>
    </div>
  </footer>
);

export default LandingFooter;