import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTES} from '../constants/ROUTES';
import Header from '../components/LandingHeader';
import LandingFooter from '../components/LandingFooter';
import DownloadLinks from '../components/DownloadLinks';
import heroMobileImg from '../imgs/hero_img_m.png';
import heroMobileImgMedium from '../imgs/hero_img_m@2x.png';
import heroMobileImgLarge from '../imgs/hero_img_m@3x.png';
import heroImg from '../imgs/hero_img.png';
import heroImgMedium from '../imgs/hero_img@2x.png';
import heroImgLarge from '../imgs/hero_img@3x.png';
// import '../styles/landing.css';

const Landing = () => {
  return (
    <div className="page__wrapper_landing">
      <Header />
      <div className="content">
        {/* <!--Hero--> */}
        <section className="hero">
          <div className="site-width site-width-landing">
            <div className="section__content">
              <figure style={{ objectFit: 'contain' }} className="hero__image mobile">
                <img
                  style={{ width: '100%', height: '100%' }}
                  className=""
                  src={heroImgMedium}
                  srcSet={`${heroImgMedium} 2x, ${heroImgMedium} 3x`}
                  alt="{UserName}"
                />
              </figure>
              <figure className="hero__image desktop">
                <img
                  className="desktop"
                  src={heroImg}
                  srcSet={`${heroImgMedium} 2x, ${heroImgLarge} 3x`}
                  alt="{UserName}"
                />
              </figure>
              <div className="hero__block">
                <div className="hero__logo desktop">
                  <i className="icons logo"></i>
                </div>
                <h1 className="hero__title section__title">Comedy gone mad!</h1>
                <div className="hero__text">
                  <p>
                    Join in with the Worlds Top Comedians for Chats, Laughs, Gigs
                    & Giggles.
                  </p>
                  <p>
                    All of our product features were designed with you in mind.
                  </p>
                </div>
                <div className="app__links">
                  <ul className="links__list">
                    <li className="links__item">
                      <a
                        className="links__link appstore"
                        target="_blank"
                        href="https://apps.apple.com/ua/app/ilaugh-app/id1516551814"
                      />
                    </li>
                    <li className="links__item">
                      <a
                        className="links__link googleplay"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.ilaugh"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--/Hero-->
        <!--Features--> */}
        <section className="features" id="features">
          <div className="site-width">
            <h3 className="section__subtitle">Our Awesome Features</h3>
            <h2 className="section__title">
              Our features help you express yourself and connect with the people
              you love.
            </h2>
            <div className="features__list">
              <div className="features__item">
                <div className="item__count">01</div>
                <div className="item__text">
                  <p>Create and share with your friends.</p>
                  <p>Post moments from your everyday life in your posts.</p>
                </div>
              </div>
              <div className="features__item">
                <div className="item__count">02</div>
                <div className="item__text">
                  <p>
                    Send photos, videos, and messages privately to friends ,
                    communicate with your Friends everywhere.
                  </p>
                </div>
              </div>
              <div className="features__item">
                <div className="item__count">03</div>
                <div className="item__text">
                  <p>
                    Discover original video content from creators you already
                    love, or new accounts you might like.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--/Features-->
        <!--Events--> */}
        <section className="events-block">
          <div className="site-width">
            <h3 className="section__subtitle">
              Create new Events and buy the tickets
            </h3>
            <h2 className="section__title">
              Create events on the web version of the site.
              <br />
              You can also buy tickets to events created by your friends
            </h2>
            <div className="section__btns">
              <Link className="btns btn-gradient" to={ROUTES.EVENTS}>
                All Events
              </Link>
            </div>
          </div>
        </section>
        {/* <!--/Events-->*/}
        <DownloadLinks />
      </div>
      <LandingFooter />
    </div>
  );
};

export default Landing;
