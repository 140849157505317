import React from 'react'

const Button = ({ children, type, accentColor, className, args, handleClick }) => {
  const buttonClass = `btns ${accentColor ? 'btn-grdnt' : 'btn-white btn-redtext'} btn-showpopup ${className}`

  return (
    <button onClick={handleClick} type={type} className={buttonClass} {...args} >{children}</button>
  )
}

export default Button
