import React from 'react'
import Popup from '../Popup'
import { useSelector, useDispatch } from 'react-redux'
import { globalActions, profileActions } from '../../redux/actions'
import { useForm } from 'react-hook-form'

const ReportModal = () => {
  const { show, reportUserId } = useSelector(state => state.globalReducer.reportModal)
  const { register, handleSubmit, formState: { errors }, reset } = useForm()

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(globalActions.toggleReportModal(null, false))
  }

  const onSubmit = data => {
    // console.log(data)
    dispatch(profileActions.reportUser({ id: reportUserId, body: data.message }))
    reset({ message: '' })
  }

  return (
    <Popup open={show} handleClose={handleClose} className="popup__block message-popup">
      <h2 className="message-popup__headline">Report</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea { ...register('message', { required: true }) } className="form__input" placeholder="Enter a message" />
        { errors.message && <p className="error">Message is required</p> }
        <input className="message-popup__submit" value="SEND" type="submit" />
      </form>
    </Popup>
  )
}

export default ReportModal
