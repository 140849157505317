import React, {useEffect} from 'react';
import Slider from 'react-slick';
import DownloadLinks from '../components/DownloadLinks';
import Event from '../components/Event';
import UpcomingEvent from '../components/UpcomingEvent';
import Header from '../components/LandingHeader';
import Footer from '../components/LandingFooter';

const Events = props => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    infinite: true,
    arrows: false,
    centerMode: true,
    variableWidth: true,
    appendDots: dots => <ul>{dots}</ul>,
  };
  const {getEvents, getUpcomingEvents, events, upcoming_events} = props;
  useEffect(() => {
    getEvents();
    getUpcomingEvents();
  }, []);
  return (
    <div className="page__wrapper_landing">
      <Header />
      <div className="content">
        {/* <!--Events All--> */}
        <section className="events-all">
          <h3 className="page__subtitle">Buy ticket to our events</h3>
          <h1 className="page__title">All Events</h1>
          <Slider className="events__slider" {...settings}>
            {events.map((event) => (
              <Event key={event.id} event={event} />
            ))}
          </Slider>
        </section>
        {/* <!--/Events All-->
        <!--Events New--> */}
        <section className="events-new">
          <div className="site-width">
            <div className="section__title">New on the site</div>
            <div className="events__list">
              {upcoming_events.map((event) => (
                <UpcomingEvent key={event.id} event={event} />
              ))}
            </div>
          </div>
        </section>
        {/* <!--/Events New--> */}
        <DownloadLinks />
      </div>
      <Footer />
    </div>
  );
};

export default Events;