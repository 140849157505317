import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from '../redux/actions'
import PostSelect from './Feed/PostSelect'

import { config } from '../config'
import emojiImage from '../imgs/emoji_small.png'
import {ReactComponent as LogoIcon} from '../imgs/logo_ilaugh.svg'


const Header = ({ secondaryColor = true, tabs = false, hideUser = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(state => state.authReducer.user)

  const handleLogout = () => {
		dispatch(authActions.logout())
		history.push('/')
	}

  return (
  <header className="header" style={secondaryColor ? { backgroundColor: 'rgba(246, 248, 251, 0.1)'} : {} }>
    <div className="site-width">
      <div className="header__content">
        <Link className="header__logo" to="/">
          <LogoIcon />
        </Link>
        { tabs && <PostSelect /> }
        { !hideUser && <div className="header__actions">
          <figure class="comment__image avatar avatar--round header__avatar">
            <Link to={`/users/${user?.id}`}><img class="image" src={user?.avatar_thumb_url ? config.REACT_APP_IMAGE_URL + user?.avatar_thumb_url : emojiImage } alt="Avatar" /></Link>
          </figure>
          {/* <button onClick={handleLogout} className="header__logout">Log out</button> */}
        </div> }
      </div>
    </div>
  </header>
  )
}

export default Header
