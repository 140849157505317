import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Events from '../pages/Events';
import {eventsActions} from '../redux/actions';

const mapStateToProps = state => ({
  events: state.eventsReducer.events,
  upcoming_events: state.eventsReducer.upcoming_events,
});

const mapDispatchToProps = dispatch => ({
  getEvents: bindActionCreators(eventsActions.getEvents, dispatch),
  getUpcomingEvents: bindActionCreators(
    eventsActions.getUpcomingEvents,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);