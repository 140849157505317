export default {
  GET_CHATS: 'GET_CHATS',
  GET_CHATS_SUCCESS: 'GET_CHATS_SUCCESS',
  GET_CHATS_NEXT_PAGE_SUCCESS: 'GET_CHATS_NEXT_PAGE_SUCCESS',
  GET_CHATS_ERROR: 'GET_CHATS_ERROR',
  GET_MESSAGES : 'GET_MESSAGES',
  GET_MESSAGES_SUCCESS : 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_ERROR : 'GET_MESSAGES_ERROR',
  CREATE_CHAT: 'CREATE_CHAT',
  CREATE_CHAT_SUCCESS: 'CREATE_CHAT_SUCCESS',
  CREATE_CHAT_ERROR: 'CREATE_CHAT_ERROR',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_MESSAGE_FROM_MODAL: 'SEND_MESSAGE_FROM_MODAL',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR',
  RECEIVED_MESSAGE: 'RECEIVED_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_ERROR: 'DELETE_MESSAGE_ERROR',
  CLEAR_CHAT: 'CLEAR_CHAT',
  RECEIVED_CHAT: 'RECEIVED_CHAT',
  UPDATE_CHAT: 'UPDATE_CHAT',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  WEB_SOCKET_CONNECTION: 'WEB_SOCKET_CONNECTION',
  TOGGLE_MESSAGE_POPUP: 'TOGGLE_MESSAGE_POPUP',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};
