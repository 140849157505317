import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { profileActions } from '../../redux/actions'
// import { useParams } from 'react-router-dom'
import { config } from '../../config'

import './Profile.css'
import DonatePopup from './DonatePopup'
import ProfilePosts from './ProfilePosts'

import emojiImage from '../../imgs/emoji_small.png'
import { ReactComponent as VerifiedIcon } from '../../imgs/verified.svg'

// @todo post detail

const MyProfileComponent = () => {
  const history = useHistory()
  const currentUser = useSelector(state => state.authReducer.user)
  const user = useSelector(state => state.profileReducer.user)
  const posts = useSelector(state => state.postsReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if(!currentUser) {
      history.push('/')
    }
    dispatch(profileActions.getUserDetails(currentUser?.id))
    // dispatch(postsActions.getUserPosts(posts.myPostsMeta, currentUser?.id))
  }, [])

  const getNumberMask = (count) => {
    let str = ''
    if(count / 10e5 >= 1) str = `${Math.floor(count/10e5)} m`
    else if(count/10e2 >= 1) str = `${Math.floor(count/10e2)} k`
    else return str = `${count}`
    return str;
  }

  // useEffect(() => {}, [user])
  let img = user ? user.avatar_url : null;

  return user ? (
  <div className="page__content content__blur content__flex">
   <div className="profile" id="profilePosts">
    <div className="user__info">
      <div className="user">
        <figure className="user__image avatar">
          <img className="image" src={`${img ? config.REACT_APP_IMAGE_URL + img : emojiImage}`} alt="Avatar" />
        </figure>
        <div className="user__fullname">
          {user.full_name}
        </div>
        <div className="user__username">
          @{user.user_name} {user.verified && <VerifiedIcon className="icons i22x22 svg" />}
        </div>
        <Link to="/ratings">
          <div className="user__rate">
            {user.user_position > 0 ? user.user_position + 'th' : 'unranked' }
          </div>
        </Link> 
          
        <div className="user_stats">
          <div className="stat">
            <span className="counter">{user.followed_users_count}</span>
            <span className="text">Following</span>
          </div>
          <div className="stat">
            <span className="counter">{getNumberMask(user.likes_count)}</span>
            <span className="text">Laughs</span>
          </div>
          <div className="stat">
            <span className="counter">{user.followers_count}</span>
            <span className="text">Followers</span>
          </div>
        </div>
      </div>
    </div>
    <ProfilePosts id={currentUser?.id} />

    </div>
  </div>
  ) : <h1>Loading</h1>
}

export default MyProfileComponent