import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../components/Header'
import LoginButton from '../components/Buttons/LoginButton'
import RegisterButton from '../components/Buttons/RegisterButton'
import PageWrapper from '../components/PageWrapper'
import LoginPopup from '../components/LoginPopup/LoginPopup'
import RegisterPopup from '../components/RegisterPopup/RegisterPopup'

import { useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'

const Home = () => {
  const history = useHistory()
  const showLoginModal = useSelector(state => state.globalReducer.showLoginModal)
  const user = useSelector(state => state.authReducer.user)

  useEffect(() => {
    if(user && user?.is_finished_signup) {
      history.push('/feed')
    }
  }, [user])

  return (
    <>
      <div className={`page__wrapper page__bg page__home ${showLoginModal && 'blur'}`}>
      <Header secondaryColor={false} hideUser />
      <div className="content__wrapper">
      <div className="page__content">	
        <div className="welcome">
          <div className="welcome__sitename"><i className="icons bg ilaugh"></i></div>
          <div className="welcome__title">Comedy gone mad!</div>
          <div className="welcome__text">
            Join in with the Worlds Top Comedians for Chats, Laughts, Gigs & Giggles .....
          </div>
          <div className="welcome__btns">
            <LoginButton />
            <RegisterButton />
            {/* <button className="btns btn-grdnt btn-showpopup" data-target=".popup__signup">Sing Up</button> */}
          </div>
        </div>
      </div>
      </div>
      </div>
    <LoginPopup />
    <RegisterPopup />
    </>
  )
}

export default Home
