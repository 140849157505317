import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { messagingActions } from '../../redux/actions'
import { useForm } from 'react-hook-form'

const MessagesForm = () => {
  const chat = useSelector(state => state.messagingReducer.chat)
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const dispatch = useDispatch()

  const onSubmit = (data, e) => {
    dispatch(messagingActions.sendMessage({
      chat_id: chat.id,
      body: data.msg      
     }))

     reset({ msg: '' })
  }

  const handleEnter = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="message-form">
      <textarea { ...register("msg", { required: true }) } onKeyPress={handleEnter} type="text" className="message-form__message" placeholder="Enter message" />
      <button className="message-form__submit" type="submit">Send</button>
    </form>
  )
}

export default MessagesForm
