import {takeEvery, put, call} from 'redux-saga/effects';
import {EVENTS_CONSTS} from '../constants';
import {eventsActions} from '../actions';
import {processRequest} from '../../services/Api';

function* handleGetEvents() {
  try {
    const {data} = yield call(processRequest, '/events', 'GET');
    if (data.events) {
      yield put(eventsActions.getEventsSuccess(data.events));
    }
  } catch (e) {
    console.log(e);
    yield put(eventsActions.getEventsError(e));
  }
}

function* handleGetUpcomingEvents() {
  try {
    const {data} = yield call(processRequest, '/events/latest', 'GET');
    if (data.events) {
      const upcoming_events = data.events.slice(0, 6);
      yield put(eventsActions.getUpcomingEventsSuccess(upcoming_events));
    }
  } catch (e) {
    yield put(eventsActions.getUpcomingEventsError(e));
  }
}

function* handleGetPastEvents() {
  try {
    const {data} = yield call(processRequest, '/events/past', 'GET');
    if (data.events) {
      yield put(eventsActions.getEventsSuccess(data.events));
    }
  } catch (e) {
    console.log(e);
    yield put(eventsActions.getEventsError(e));
  }
}

function* handleGetEvent(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const {data} = yield call(processRequest, `/events/${id}`, 'GET');
    if (data.event) {
      yield put(eventsActions.getEventSuccess(data.event));
    }
  } catch (e) {
    yield put(eventsActions.getEventError(e));
  }
}

export function* watchEventsSagas() {
  yield takeEvery(EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENTS, handleGetEvents);
  yield takeEvery(EVENTS_CONSTS.EVENTS_ACTIONS.GET_PAST_EVENTS, handleGetPastEvents);
  yield takeEvery(
    EVENTS_CONSTS.EVENTS_ACTIONS.GET_UPCOMING_EVENTS,
    handleGetUpcomingEvents,
  );
  yield takeEvery(EVENTS_CONSTS.EVENTS_ACTIONS.GET_EVENT, handleGetEvent);
}
