import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postsActions, globalActions } from '../../redux/actions'

import { ReactComponent as ShareIcon } from '../../imgs/icons/i-share.svg'
import { ReactComponent as AlertIcon } from '../../imgs/icons/i-alert.svg'
import { ReactComponent as DownloadIcon } from '../../imgs/icons/i-download.svg'
import { ReactComponent as ShowMoreIcon } from '../../imgs/icons/i-opt.svg'
import { ReactComponent as CommentIcon } from '../../imgs/icons/i-comment.svg'
import { ReactComponent as ViewsIcon } from '../../imgs/icons/i-views.svg'
import { ReactComponent as LolIcon } from '../../imgs/icons/i-lol.svg'
import { ReactComponent as LolActiveIcon } from '../../imgs/icons/i-lol-active.svg'

import { ReactComponent as SendIcon } from '../../imgs/icons/i-send.svg'


const PostActions = ({ post }) => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.authReducer.user)

  const handleLike = () => {
    dispatch(postsActions.likePost(post.id))
  }

  const handleReport = () => {
    dispatch(globalActions.toggleReportModal(post.user.id, true))
  }


  return (
    <div class="info__actions">
      {/* <div class="menu menu--top">
        <div class="menu__list">
          <li class="menu__item">
            <button class="menu__btn btns btn-share">
              <ShareIcon className="icons svg i36x36" />
            </button>
          </li>
          <li class="menu__item">
            <button class="menu__btn btns btn-alert">
              <AlertIcon className="icons svg i36x36" />
            </button>
          </li>
          <li class="menu__item">
            <button class="menu__btn btns btn-download">
              <DownloadIcon className="icons svg i36x36" />
            </button>
          </li>
        </div>
      </div> */}
      <div class="menu menu--bottom">
        <ul class="menu__list">
          {/* <li class="menu__item">
            <button class="menu__btn btns btn-more">
              <ShowMoreIcon className="icons svg i32x32 btn__more" />
              <span class="counter"></span>
            </button>
          </li> */}
          { post.user.id !== user.id && 
            <li class="menu__item" onClick={handleReport}>
              <button class="menu__btn btns btn-alert">
                <AlertIcon className="icons svg i36x36" />
              </button>
            </li>
          }
          <li class="menu__item">
            <button class="menu__btn btns btn-comment cursor--default">
              <CommentIcon className="icons svg i40x40" />
            </button>
            <span class="counter">{post.replies_count}</span>
          </li>
          <li class="menu__item">
            <button class="menu__btn btns btn-views cursor--default">
              <ViewsIcon className="icons svg i40x40" />
            </button>
            <span class="counter">{post.views_count}</span>
          </li>
          <li class="menu__item">
            <button onClick={handleLike} class="menu__btn btns btn-like">
              { post.is_liked ? <LolActiveIcon className="icons svg i40x40" /> : <LolIcon className="icons svg i40x40" /> }
            </button>
            <span class="counter">{post.likes_count}</span>
          </li>
          {/* <li class="menu__item">
            <button class="menu__btn btns btn-send">
              <SendIcon className="icons svg i40x40" />
            </button>
            <span class="counter"></span>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default PostActions
