import React, { useState, useEffect } from 'react'
import { config } from '../../config'
import PostActions from './PostActions'
import PostInfo from './PostInfo'


const PostSlide = ({ post, img, thumbImg }) => {
  const [paused, setPaused] = useState(true)

  const handleClick = e => {
    paused ? e.target.play() : e.target.pause()
    setPaused(prevPaused => !prevPaused)
  }

  return (
    <>
      {img.slice(-3).toLocaleLowerCase() !== 'mp4' ? (
        <figure 
          className="post__image"
          style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + img})`}}
        >
          <img src={config.REACT_APP_IMAGE_URL + img} alt="post image"/>
          <PostActions post={post} />
          <PostInfo post={post} />
        </figure>
      ) : (
        <figure 
          className="post__image"
          style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + thumbImg})`}}
        >
          <video onClick={handleClick} controlsList="nodownload">
            <source src={config.REACT_APP_IMAGE_URL + img} type="video/mp4" />
          </video>
          <PostActions post={post} />
          <PostInfo post={post}  />
        </figure>
      )}
    </>
  )
}

export default PostSlide