import React, { useState } from 'react'
import PastEvents from './PastEvents'
import UpcommingEvents from './UpcommingEvents'
// import { useSelector, useDispatch } from 'react-redux'
// import { eventsActions } from '../../redux/actions'

const Events = () => {
  const [selector, setSelector] = useState('upcoming')
  // const dispatch = useDispatch()
  // const events = useSelector(state => state.eventsReducer)

  // useEffect(() => {
  //   dispatch(eventsActions.getEvents())
  // }, [])

  return (
    <div class="EventsTickets">
      <div class="EventsTickets__tabs">
        <div class="tabs">
          <ul class="menu__list menu__list--horizontal">
            <li class="menu__item menu__item--01">
              <a class="menu__link"  href="#events">EVENTS</a>
            </li>
            <li class="menu__item menu__item--02">
              <a class="menu__link"  href="#tickes">MY TICKETS</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="events-tickets__lists">
        <div class="events-tickets__events" id="events">
          <div class="events">
            <div class="events__tabs">
              <div class="tabs">
                <ul class="menu__list menu__list--horizontal">
                  <li class="menu__item menu__item--01">
                    <a onClick={() => setSelector('upcoming')} className={`menu__link event__link ${selector === 'upcoming' && 'active'}`}>Upcoming Events</a>
                  </li>
                  <li class="menu__item menu__item--02">
                    <a onClick={() => setSelector('past')} className={`menu__link event__link ${selector === 'past' && 'active'}`}>Past Events</a>
                  </li>
                </ul>											
              </div>
            </div>
              { selector === 'upcoming' ? <UpcommingEvents /> : <PastEvents /> }
            </div>
          </div>
        </div>
        <div class="EventsTickets__tickets" id="tickets">
        </div>
      </div> 
      )
}

export default Events
