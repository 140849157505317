import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ContactUs from '../pages/ContactUs';
import {globalActions} from '../redux/actions';

const mapStateToProps = state => ({
  user: state.authReducer.user,
});

const mapDispatchToProps = dispatch => ({
  sendContactUs: bindActionCreators(globalActions.sendContactUs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);