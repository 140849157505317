import {fork} from 'redux-saga/effects';
import {watchEventsSagas} from './eventsSagas';
import {watchAuthSagas} from './authSagas';
import {watchPaymentsSagas} from './paymentsSagas';
import {watchGlobalSagas} from './globalSagas';
import {watchPostsSagas} from './postsSagas';
import {watchProfileSagas} from './profileSagas';
import {watchMessagingSagas} from './messagingSagas';

export default function* rootSaga() {
  yield fork(watchEventsSagas);
  yield fork(watchAuthSagas);
  yield fork(watchPaymentsSagas);
  yield fork(watchGlobalSagas);
  yield fork(watchPostsSagas);
  yield fork(watchProfileSagas);
  yield fork(watchMessagingSagas);
}
