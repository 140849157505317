import axios from 'axios';
import {LOCAL_STORAGE} from '../redux/constants';
import {config} from '../config';

import { getToken } from './LocalStorage'

// function checkStatus(responseData: any) {
//   if (responseData.status >= 200 && responseData.status < 300)
//     return {
//       data: responseData.data,
//       headers: responseData.headers,
//       status: responseData.status,
//     };
//   const error = {
//     message:
//       (responseData.data.response && responseData.data.response.message) || '',
//     response: responseData.data,
//   };

//   // error.response = responseData.data;
//   throw error;
// }

export function processRequest(
  url = '',
  method = 'GET',
  data = {},
  json = true,
) {

  const token = getToken()
  const headers = {
    'Content-Type': json ? 'application/json' : 'multipart/form-data',
    Authorization: token || '',
  };
  
  const body = json ? JSON.stringify(data) : data;
  const configs = {
    method,
    baseURL: config.REACT_APP_API_URL + url,
    data: body,
    headers: headers,
  };
  return axios(configs).then((res) => res);
}
