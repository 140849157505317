import React, { useState } from 'react'
import EditPopup from './EditPopup'
import Events from './Events'
import PrivacyPopup from './PrivacyPopup'
import PasswordPopup from './PasswordPopup'
import SuccessPopup from '../SuccessPopup/SuccessPopup'
import './Settings.css'
import TermsPopup from './TermsPopup'
import { useHistory } from 'react-router-dom'
import { authActions } from '../../redux/actions'
import { useDispatch } from 'react-redux'

const SettingsComponent = () => {
	const [changePassword, setChangePassword] = useState(false)
	const [editProfile, setEditProfile] = useState(false)
	const [editPrivacy, setEditPrivacy] = useState(false)
	const [termsPopup, setTermsPopup] = useState(false)
	const dispatch = useDispatch()
	const history = useHistory()

	const handleLogout = () => {
		dispatch(authActions.logout())
		history.push('/')
	}

  return (
    <div class="page__content content__blur content__flex settings" id="tabs">
					{/* <!--Events & Tickes--> */}
					<Events />
					{/* <!--/Events & Tickes--> */}
					{/* <!--Settings & Pages--> */}
					<div class="SettingsPages">
						{/* <!--Settings--> */}
						<div class="settings">
							<div class="settings__menu">
								<div class="menu">
									<ul class="menu__list">
										<li class="menu__item">
											<button onClick={() => setEditProfile(!editProfile)} class="menu__btn btns btn-showpopup" data-target=".popup__profile">
												EDIT PROFILE
												<svg class="icons svg i24x24">
												  {/* <use xlink:href="#i-arrow_right"></use> */}
												</svg>
											</button>
										</li>
										<li class="menu__item">
											<button onClick={ () => setChangePassword(!changePassword) } class="menu__btn btns btn-showpopup" data-target=".popup__password">
												CHANGE PASSWORD
												<svg class="icons svg i24x24">
												  {/* <use xlink:href="#i-arrow_right"></use> */}
												</svg>
											</button>
										</li>
										<li class="menu__item">
											<button onClick={() => setEditPrivacy(!editPrivacy)} class="menu__btn btns btn-showpopup" data-target=".popup__privacy">
												PRIVACY
												<svg class="icons svg i24x24">
												  {/* <use xlink:href="#i-arrow_right"></use> */}
												</svg>
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!--/Settings--> */}
						{/* <!--Pages--> */}
						<div class="pages">
							<div class="pages__menu">
								<div class="menu">
									<ul class="menu__list">
									<li class="menu__item">
											<button class="menu__btn btns">
												BLACK LIST
												<svg class="icons svg i24x24">
													{/* <use xlink:href="#i-arrow_right"></use> */}
												</svg>
											</button>
										</li>
										<li class="menu__item">
											<button class="menu__btn btns">
												LEAVE FEEDBACK
												<svg class="icons svg i24x24">
												  {/* <use xlink:href="#i-arrow_right"></use> */}
												</svg>
											</button>
										</li>
										<li class="menu__item">
											<button class="menu__btn btns" onClick={() => setTermsPopup(!termsPopup)}>
												TERMS & CONDITIONS / PRIVACY POLICY
												<svg class="icons svg i24x24">
												  {/* <use xlink:href="#i-arrow_right"></use> */}
												</svg>
											</button>
										</li>
									</ul>		
								</div>
							</div>
						</div>
						{/* <!--/Pages--> */}
						<div class="SettingsPages__btns">
							<button onClick={handleLogout} class="btns btn-red btn-logout">LOG OUT</button>
						</div>
					</div>
					{/* <!--/Settings & Pages--> */}
					<PasswordPopup handleClose={setChangePassword} open={changePassword}/>
					<EditPopup handleClose={setEditProfile} open={editProfile} />
					<PrivacyPopup handleClose={setEditPrivacy} open={editPrivacy} />
					<TermsPopup handleClose={setTermsPopup} open={termsPopup} />
					<SuccessPopup />
				</div>
  )
}

export default SettingsComponent
