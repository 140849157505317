import {takeEvery, put, call} from 'redux-saga/effects';
import {appHistory} from '../../services/HistoryConfig';
import {PAYMENTS_CONSTS} from '../constants';
import {paymentActions, globalActions} from '../actions';
import {processRequest} from '../../services/Api';

function* handleBuyTicket(action) {
  try {
    const {payload} = action;
    const {payment: {
      stripe_email, paymentMethod, amount, event_id, stripe,
    }} = payload;
    const requestPayload = {payment:{
      stripe_email,
      amount,
      event_id,
    }};
    const {data} = yield call(
      processRequest,
      '/stripe_payments',
      'POST',
      requestPayload,
    );
    const {client_secret} = data || {};
    if(client_secret) {
      const { paymentIntent } = yield call(stripe.confirmCardPayment, client_secret, {
        payment_method: paymentMethod.id
      });
      if(paymentIntent.status === 'succeeded')  {
        yield put(paymentActions.buyTicketSuccess());
        appHistory.goBack();
        yield put(globalActions.setSuccess(data.message || ''));
      }
    }
  } catch (e) {
    const {response} = e;
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (typeof error_messages === 'string') {
        message = error_messages;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(paymentActions.buyTicketError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(paymentActions.buyTicketError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(
        paymentActions.buyTicketError(statusText || 'Internal server error.'),
      );
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(paymentActions.buyTicketError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(paymentActions.buyTicketError('Internal server error.'));
    }
  }
}

function* handleDonate (action) {
  const {payload} = action;
  const { billingDetails, cardElement, stripe } = payload.payment;

  // get clientSecret
  try {
    yield put(paymentActions.toggleIsProccessing(true))
    const { data: { client_secret } } = yield call(processRequest, '/donates/stripe', 'POST', {...billingDetails, platform: 'stripe'})
    console.log(client_secret)

    // create payment method
    const {error, paymentMethod} = yield call(stripe.createPaymentMethod, {
      type: "card",
      card: cardElement
    })
    // confirm payment
    const { paymentIntent } = yield call(stripe.confirmCardPayment, client_secret, {
      payment_method: paymentMethod.id
    })

    if(paymentIntent.status === 'succeeded') {
      // console.log('ksksaksak')
      // yield put(paymentActions.donateSuccess(paymentIntent))
      yield put(paymentActions.toggleDonateModal())
      yield put(globalActions.toggleSuccessModal({ show: true, message: 'Donate was successfully sent' }))
    }

    yield put(paymentActions.toggleIsProccessing(false))
    // console.log(confirmedCardPayment)
    
  } catch (e) {
    console.log(e.message)
    yield put(paymentActions.toggleIsProccessing(false))
  }
  
}

export function* watchPaymentsSagas() {
  yield takeEvery(PAYMENTS_CONSTS.PAYMENTS_ACTIONS.BUY_TICKET, handleBuyTicket);
  yield takeEvery(PAYMENTS_CONSTS.PAYMENTS_ACTIONS.DONATE, handleDonate);
}
