import {
  PROFILE_CONSTS
} from '../constants';

const {
  PROFILE_ACTIONS
} = PROFILE_CONSTS;

export const follow = (id) => ({
  type: PROFILE_ACTIONS.FOLLOW,
  payload: {
    id
  },
});

export const unfollow = (id) => ({
  type: PROFILE_ACTIONS.UNFOLLOW,
  payload: {
    id
  },
});

export const followSuccess = (id) => ({
  type: PROFILE_ACTIONS.FOLLOW_SUCCESS,
  payload: {
    id
  },
});

export const unfollowSuccess = (id) => ({
  type: PROFILE_ACTIONS.UNFOLLOW_SUCCESS,
  payload: {
    id
  },
});

export const followError = (error) => ({
  type: PROFILE_ACTIONS.FOLLOW_ERROR,
  payload: {
    error
  },
});

export const unfollowError = (error) => ({
  type: PROFILE_ACTIONS.UNFOLLOW_ERROR,
  payload: {
    error
  },
});

export const updateProfile = (user, register = false) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PROFILE,
    payload: {
      user,
      register
    },
  };
};

export const updateProfileSuccess = (profile) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PROFILE_SUCCESS,
    payload: {
      profile
    },
  };
};

export const updateProfileError = (error) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PROFILE_ERROR,
    payload: {
      error
    },
  };
};

export const updatePassword = (passwords) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PASSWORD,
    payload: {passwords},
  };
};

export const updatePasswordSuccess = () => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PASSWORD_SUCCESS,
  };
};

export const updatePasswordError = (error) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PASSWORD_ERROR,
    payload: {error},
  };
};

export const reportUser = (reportable) => {
  return {
    type: PROFILE_ACTIONS.REPORT_USER,
    payload: {reportable},
  };
};

export const reportUserError = (error) => ({
  type: PROFILE_ACTIONS.REPORT_USER_ERROR,
  payload: {error},
});

export const reportUserSuccess = (id) => {
  return {
    type: PROFILE_ACTIONS.REPORT_USER_SUCCESS,
    payload: {id},
  };
};


export const block = (id) => ({
  type: PROFILE_ACTIONS.BLOCK,
  payload: {id},
});

export const blockSuccess = (id) => ({
  type: PROFILE_ACTIONS.BLOCK_SUCCESS,
  payload: {id}
});

export const unblock = (id) => ({
  type: PROFILE_ACTIONS.UNBLOCK,
  payload: {id},
});

export const unblockSuccess = (id) => ({
  type: PROFILE_ACTIONS.UNBLOCK_SUCCESS,
  payload: {id},
});

export const blockUnblockError = (error) => ({
  type: PROFILE_ACTIONS.BLOCK_UNBLOCK_ERROR,
  payload: {error},
});

export const getCurrentUser = () => ({
  type: PROFILE_ACTIONS.GET_CURRENT_USER,
  payload: {},
});

export const getCurrentUserSuccess = (user) => ({
  type: PROFILE_ACTIONS.GET_CURRENT_USER_SUCCESS,
  payload: {
    user
  },
});

export const getCurrentUserError = (error) => ({
  type: PROFILE_ACTIONS.GET_CURRENT_USER_ERROR,
  payload: {
    error
  },
});

export const getUserDetails = (user_id) => ({
  type: PROFILE_ACTIONS.GET_USER_DETAILS,
  payload: {
    user_id
  },
});

export const getUserDetailsSuccess = (user) => ({
  type: PROFILE_ACTIONS.GET_USER_DETAILS_SUCCESS,
  payload: {
    user
  },
});

export const getUserDetailsError = (error) => ({
  type: PROFILE_ACTIONS.GET_USER_DETAILS_ERROR,
  payload: {
    error
  },
});

export const getRatings = (ratingUsersMeta) => ({
  type: PROFILE_ACTIONS.GET_RATINGS,
  payload: {
    ratingUsersMeta
  },
});

export const getRatingsSuccess = (ratingUsers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_RATINGS_SUCCESS,
  payload: {
    ratingUsers,
    total_pages
  },
});

export const getRatingsNextPageSuccess = (ratingUsers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_RATINGS_NEXT_PAGE_SUCCESS,
  payload: {
    ratingUsers,
    total_pages
  },
});

export const getRatingsError = (error) => ({
  type: PROFILE_ACTIONS.GET_RATINGS_ERROR,
  payload: {
    error
  },
});

export const uploadAvatar = (avatar, user, register) => ({
  type: PROFILE_ACTIONS.UPLOAD_AVATAR,
  payload: {
    avatar,
    user,
    register,
  }
})

export const uploadAvatarError = (error) => ({
  type: PROFILE_ACTIONS.UPLOAD_AVATAR_ERROR,
  payload: {error}
})

export const getUsers = (usersMeta) => ({
  type: PROFILE_ACTIONS.GET_USERS,
  payload: {usersMeta},
});

export const getUserSuccess = (users, total_pages) => ({
  type: PROFILE_ACTIONS.GET_USERS_SUCCESS,
  payload: {users, total_pages},
});

export const getUsersNextPageSuccess = (users, total_pages) => ({
  type: PROFILE_ACTIONS.GET_USERS_NEXT_PAGE_SUCCESS,
  payload: {users, total_pages},
});

export const getUsersError = (error) => ({
  type: PROFILE_ACTIONS.GET_USERS_ERROR,
  payload: {error},
});
