import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import EventPage from '../pages/Event';
import {eventsActions, globalActions} from '../redux/actions';

const mapStateToProps = state => ({
  user: state.authReducer.user,
  showLoginModal: state.globalReducer.showLoginModal,
  event: state.eventsReducer.event,
});

const mapDispatchToProps = dispatch => ({
  getEvent: bindActionCreators(eventsActions.getEvent, dispatch),
  toggleLoginModal: bindActionCreators(
    globalActions.toggleLoginModal,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);