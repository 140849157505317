import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ROUTES} from '../constants/ROUTES';

const LandingHeader = () => {
  const [showMobileMenu, setShowMenu] = useState(false);
  const handleShowMenu = () => {
    setShowMenu(!showMobileMenu);
  };
  return (
    <header
      className={`landing-header ${
        showMobileMenu ? 'show-menu' : ''
      }`}>
      <div className="site-width site-width-landing">
        <div className="header__logo">
          <Link
            className="logo__link"
            to={ROUTES.LANDING}
            onClick={handleShowMenu}>
            <i className="icons logo" />
          </Link>
        </div>
        <div className="header__block">
          <div className="header__menu">
            <nav className="header__nav">
              <ul className="menu__list">
                <li className="menu__item">
                  <Link
                    className="menu__link"
                    to={ROUTES.LANDING}
                    onClick={handleShowMenu}>
                    Home
                  </Link>
                </li>
                <li className="menu__item">
                  <a
                    className="menu__link"
                    href="/#features"
                    onClick={handleShowMenu}>
                    Our features
                  </a>
                </li>
                <li className="menu__item">
                  <Link
                    className="menu__link"
                    to={ROUTES.EVENTS}
                    onClick={handleShowMenu}>
                    Events
                  </Link>
                </li>
                <li className="menu__item">
                  <Link
                    className="menu__link"
                    to={ROUTES.CONTACT_US}
                    onClick={handleShowMenu}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header__profile">
            <div className="profile__notlogged">
              <div className="profile__btns">
                <Link
                  className="btns btn-gradient"
                  to={ROUTES.HOME}>
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="header__btns">
          <button className="btns btn-showMenu mobile" onClick={handleShowMenu}>
            <i
              className={`icons i24x24 i-hamburger ${
                showMobileMenu ? 'i-close' : ''
              }`}
            />
          </button>
          {/* <!-- IF NotLoggedIn  --> */}
          <Link
            className="btns btn-gradient btn-signIn"
            to={ROUTES.HOME}>
            Log in
          </Link>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;