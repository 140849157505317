import {POSTS_CONSTS} from '../constants';

const {POSTS_ACTIONS} = POSTS_CONSTS;

export const getPosts = (meta) => ({
  type: POSTS_ACTIONS.GET_POSTS,
  payload: {meta},
});

export const getPost = (id) => ({
  type: POSTS_ACTIONS.GET_POST,
  payload: {id},
});

export const getPostsSuccess = (posts, total_pages) => ({
  type: POSTS_ACTIONS.GET_POSTS_SUCCESS,
  payload: {posts, total_pages},
});

export const getPostSuccess = (post) => ({
  type: POSTS_ACTIONS.GET_POST_SUCCESS,
  payload: {post},
});

export const likePost = (id) => ({
  type: POSTS_ACTIONS.LIKE_POST,
  payload: {id},
});

export const likePostSuccess = (id, likes_count) => ({
  type: POSTS_ACTIONS.LIKE_POST_SUCCESS,
  payload: {id, likes_count},
});

export const getPostsError = (error) => ({
  type: POSTS_ACTIONS.GET_POSTS_ERROR,
  payload: {error},
});

export const getPostError = (error) => ({
  type: POSTS_ACTIONS.GET_POST_ERROR,
  payload: {error},
});

export const likePostError = (error) => ({
  type: POSTS_ACTIONS.GET_POSTS_ERROR,
  payload: {error},
});

export const sendView = (id) => ({
  type: POSTS_ACTIONS.SEND_VIEW,
  payload: {id},
});

export const sendViewSuccess = (id) => ({
  type: POSTS_ACTIONS.SEND_VIEW_SUCCESS,
  payload: {id},
});

export const deletePost = (id) => ({
  type: POSTS_ACTIONS.DELETE_POST,
  payload: {id},
});

export const deletePostSuccess = (id) => ({
  type: POSTS_ACTIONS.DELETE_POST_SUCCESS,
  payload: {id},
});

export const createReply = (body, id) => ({
  type: POSTS_ACTIONS.CREATE_REPLY,
  payload: {body, id},
});

export const likeReply = (reply) => ({
  type: POSTS_ACTIONS.LIKE_REPLY,
  payload: {reply},
})

export const deleteReply = (id,replyId) => ({
  type: POSTS_ACTIONS.DELETE_REPLY,
  payload: {id, replyId},
});


export const reportPost = (reportable) => {
  return {
    type: POSTS_ACTIONS.REPORT_POST,
    payload: {reportable},
  };
};

export const reportPostSuccess = (post) => {
  return {
    type: POSTS_ACTIONS.REPORT_POST_SUCCESS,
    payload: {post},
  };
};

export const reportPostError = (error) => {
  return {
    type: POSTS_ACTIONS.REPORT_POST_ERROR,
    payload: {error},
  };
};


export const reportReply = reportable => {
  return {
    type: POSTS_ACTIONS.REPORT_REPLY,
    payload: { reportable }
  };
}

export const reportReplySuccess = reply => {
  return {
    type: POSTS_ACTIONS.REPORT_REPLY_SUCCESS,
    payload: { reply }
  };
}

export const reportReplyError = error => {
  return {
    type: POSTS_ACTIONS.REPORT_REPLY_ERROR,
    payload: { error }
  };
}

export const createReplySuccess = (reply) => ({
  type: POSTS_ACTIONS.CREATE_REPLY_SUCCESS,
  payload: {reply},
});

export const likeReplySuccess = (reply, likes_count) => ({
  type: POSTS_ACTIONS.LIKE_REPLY_SUCCESS,
  payload: {reply, likes_count},
})

export const deleteReplySuccess = (id, replyId) => ({
  type: POSTS_ACTIONS.DELETE_REPLY_SUCCESS,
  payload: {id, replyId},
});

export const createReplyError = (error) => ({
  type: POSTS_ACTIONS.CREATE_REPLY_ERROR,
  payload: {error},
});

export const likeReplyError = (error) => ({
  type: POSTS_ACTIONS.LIKE_REPLY_ERROR,
  payload: {error},
})

export const deleteReplyError = (error) => ({
  type: POSTS_ACTIONS.DELETE_REPLY_ERROR,
  payload: {error},
});

export const getUserPosts = (meta, id) => ({
  type: POSTS_ACTIONS.GET_USER_POSTS,
  payload: {meta, id},
});

export const getUserPostsSuccess = (userPosts, total_pages) => ({
  type: POSTS_ACTIONS.GET_USER_POSTS_SUCCESS,
  payload: {userPosts, total_pages}
});

export const getUserPostsError = (error) => ({
  type: POSTS_ACTIONS.GET_USER_POSTS_ERROR,
  payload: {error}
})

export const getMyPosts = (meta) => ({
  type: POSTS_ACTIONS.GET_MY_POSTS,
  payload: {meta},
});
