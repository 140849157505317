import React from 'react';
import {useForm} from 'react-hook-form';
import Header from '../components/LandingHeader';
import Footer from '../components/LandingFooter';

const ContactUs = ({sendContactUs, user}) => {
  const {handleSubmit, register, errors} = useForm({
    defaultValues: {
      email: user?.email || '',
      body: '',
    },
  });
  const submitForm = formData => {
    console.log(formData)
    sendContactUs(formData);
  };
  return (
    <div className="page__wrapper_landing">
      <Header />
      <div className="content">
        <section className="payment">
          <div className="site-width">
            <div className="payment__block">
              <div className="payment__header">
                <div className="payment__title"></div>
                <div className="payment__date"></div>
                <div className="payment__summ">{`Contact Us`}</div>
              </div>
              <div className="payment__content">
                <form className="form" onSubmit={handleSubmit(submitForm)}>
                  <div className="form__row">
                    <input
                      className={`form__input ${
                        errors && errors.email ? 'form__input_error' : ''
                      }`}
                      type="email"
                      name="email"
                      {...register('email', {
                        required: 'Email Required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      placeholder="Your Email"
                    />
                    {errors && errors.email && (
                      <span className="form__input_error">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="form__row">
                    <textarea
                      name="body"
                      className={`form__input ${
                        errors && errors.body ? 'form__input_error' : ''
                      }`}
                      {...register('body', {
                        required: 'Message Required',
                      })}
                      placeholder="Your message"
                    />
                    {errors && errors.body && (
                      <span className="form__input_error">
                        {errors.body.message}
                      </span>
                    )}
                  </div>
                  <div className="form__row--submit">
                    <button type="submit" className="btns btn-gradient">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
