import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { postsActions, globalActions } from '../../redux/actions'
import { Link } from 'react-router-dom'
import moment from 'moment'

import PostCommentsForm from './PostCommentsForm'
import InfiniteScroll from 'react-infinite-scroll-component';

import { config } from '../../config'
import emojiImage from '../../imgs/emoji_small.png'
import { ReactComponent as VerifiedIcon } from '../../imgs/verified.svg'

const PostComments = ({ id, replies }) => {
  const dispatch = useDispatch()
  const { post, meta } = useSelector(state => state.postsReducer)
  // const replies = useSelector(state => state.postsReducer.posts[index])
  const userId = useSelector(state => state.authReducer.user?.id)
  const comments = post ? post.replies : null;

  // console.log(replies)

  useEffect(() => {
    // console.log(id)
    dispatch(postsActions.getPost(id))
  }, [id])


  const handleLike = (reply) => {
    dispatch(postsActions.likeReply(reply))
  }

  const handleDeleteReply = (e, postId, replyId) => {
    dispatch(postsActions.deleteReply(postId, replyId))
  }

  const handleReport = id => {
    dispatch(globalActions.toggleReportModal(id, true))
  }

  console.log(meta.tab)

  return replies?.length && userId ? (
    <div className="post__comments">
      <div className="comments__list" id="commentsList">
        <InfiniteScroll dataLength={replies.length}  scrollableTarget="commentsList">
          {replies.map(comment => (
          <div class="comment">
          <figure class="comment__image avatar avatar--round">
            <Link to={`/users/${comment.user.id}`}><img class="image" src={comment.user.avatar_thumb_url ? config.REACT_APP_IMAGE_URL + comment.user.avatar_thumb_url : emojiImage } alt="Avatar" /></Link>
          </figure>
          <div class="comment__block">
            <div class="block__top">
              <div class="comment__author">@{comment.user.user_name} {comment.user.verified && <VerifiedIcon className="icons i22x22 svg" />}</div>
              <div class="comment__date">{moment(comment.created_at).fromNow()}</div>
            </div>
            <div class="comment__content">
              <p>
                { comment.body }
              </p>
            </div>
            <div class="block__bottom">
              <div class="comment__actions">
                <div class="comment__like">
                  <button onClick={(e) => handleLike(comment)} class="btns btn-like btn-done" title="unlike">like {comment.likes_count}</button>
                </div>
                <div class="comment__reply">
                  {comment.user.id === userId ? 
                  <button onClick={(e, postId, replyId) => handleDeleteReply(e, comment.post_id, comment.id)} class="btns btn-replay" title="reply">delete</button> : 
                  <button onClick={() => handleReport(comment.user.id)} class="btns btn-replay">report</button> }
                </div>
              </div>
            </div>
          </div>
        </div>
        ))}
        </InfiniteScroll>
      </div>
      <PostCommentsForm id={id} />
    </div>
  ) : <div className="post__comments post__comments--empty">
        <div className="comments__list" id="commentsList">
          <p className="no-comments">No comments yet</p>
        </div>
        <PostCommentsForm id={id} />
      </div>
}

export default PostComments
