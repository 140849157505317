import React from 'react'
import Header from '../components/Header'

const Terms = () => {
  return (
    <>
    <Header />
    <div className="public-terms">
      <div className="terms-list">
        <h1>ILAUGH TERMS OF USE</h1>
        <div className="terms-popup__block">
          <h3>Last Modified: July 5, 2021</h3>
          <p>iLaugh Limited and our subsidiaries (“we,” “us” or “iLaugh”) are a leading bespoke comedy social network and ticket sales platform. We care about protecting the personal information of our customers and visitors who use our websites, products or services (collectively, our “Users”). This privacy policy provides details about how your personal information is collected, shared and used by us. If you have any questions about this privacy policy or the practices described herein, you may contact support@ilaugh.co or Garrynamann, Kells, Kilkenny, Ireland. For the purposes of the European Union’s General Data Protection Regulation 2016/679, or GDPR, our European local representative is Brendan Morrissey at iLaugh Ltd.
          </p>
          <p>
            The purpose of this privacy policy (“Privacy Policy”) is to inform you what personally identifiable information or personal information we may collect from you when you use our application or visit this website, how we use such information, and the choices you have regarding our use of, and your ability to review and correct, the information.
          </p>
          <p>
            For purposes of this Privacy Policy, the terms “we,” “us” and “our” refer to iLaugh Limited. “You” refers to you, as a user of our services or visitor to our website, as applicable and to people that you contact through our Services (or that we contact on your behalf), or a person who you might choose to contact (or that we contact on your behalf) at some point in the future through the use of our Services. Capitalized terms not defined in this Privacy Policy have the meanings set forth in the Terms of Use. This Privacy Policy is effective as of the date last modified, as listed in above.
          </p>
          <p>This privacy policy covers personal information, including any information we collect, use and share from you, as described further below. This privacy policy applies to our website, our products and services (collectively, the “Services”). This privacy policy does not cover how our Users may use or share data that they collect using our services. When you purchase a Service from us, your personal information will be collected, used, and shared consistent with the provisions of this privacy policy.</p>

        </div>

        <div className="terms-popup__block">
          <h3>INFORMATION WE COLLECT</h3>
          <p>
            In the course of your use of the Services, we obtain the following information about you as described below. We may need to collect this information to provide you with services or information you request, or to facilitate commercial engagements with you. If you do not provide such information, we may not be able to provide services or information to you, or enter commercial engagements with you. We will only collect information about you that is necessary for one or more of our legitimate business purposes, or is required by law.

            Signing Up for Services; User Information Information you provide prior to any registration process, such as your email when you provide it to us;

            Information that you provide during any registration process, including in connection with a co-branded offer (such as your name, company name, email address, phone number, billing address or credit card information, geographic location and industry), when you call or email us (for support or otherwise) or when you use our products or services; and Note that we do not collect payment information, including credit card data. You will be directed to a third-party site to process any payments you make to iLaugh. Payment information to process your order and deliver the products or services and fulfil our contract with you. Your credit card details are passed to a third-party payment processor which is based in the USA and is certified to the EU-US Privacy Shield. We do not retain your credit card information.

            Use of Services, Website Visits and Support Data relating to your online activity on our website with respect to our Services, including the following:
          </p>
          <ul>
            <li>IP address</li>
            <li>browser type and version</li>
            <li>geographic location</li>
            <li>pages you view</li>
            <li>how you got to our Services and any links you click on to leave our Services</li>
            <li>when you update your information, communicate with us, or order new Services metadata about your use and your contacts’ use of our Services and your emails you send (including clicks and opens)</li>
            <li>your interactions with any videos we offer</li>
            <li>issues you encounter requiring our support or assistance any device or other method of communication you use to interact with the Services. We store this data we collect in a variety of places within our infrastructure, including system log files, back end databases and analytics systems.</li>
            <p>We and our partners use various technologies to collect and store information when you use our Services, and this may include sending one or more cookies or anonymous identifiers to your device. Please see our cookie policy for further information.</p>
          </ul>
        </div>
        <div className="terms-popup__block">
          <h3>Social Media</h3>
          <p>Information from third party social networking sites, including information that social networking sites provide to us if you use your credentials at such social networking sites to log into some of our Services (such as your name and email address to pre-populate our sign-up form).</p>
          <p>The information you allow us to access varies by social networking site, which depends on the level of privacy settings you have in place at the social networking site. You can control and find out more about these privacy settings at the applicable social networking site.</p>
        </div>
        <div className="terms-popup__block">
          <h3>Other Sources</h3>
          <p>Information that is publicly available; and
          Information you consent to us receiving from third parties.</p>
        </div>
        <div className="terms-popup__block">
          <h3>HOW WE USE YOUR INFORMATION</h3>
          <p>We have a legitimate interest in running a successful and efficient business and in providing you with Services and useful content, and we use the information we collect, both on its own and combined with any other information we collect about you, for the following purposes:</p>
          <ul>
            <li>To provide the requested Services to you;</li>
            <li>To provide you with useful content;</li>
            <li>To ensure the proper functioning of our Services, provided that any such information that is collected on an anonymized basis, such that it will not contain data that can personally identify any of our users.</li>
            <li>To offer and improve our Services;</li>
            <li>To provide you with requested information or technical support;</li>
            <li>To facilitate your movement through our website or your use of our Services;</li>
            <li>To do a better job of advertising and marketing our Services ( our use of your information to do this is performed with your consent where required by applicable law);</li>
            <li>To advertise and market third party products and services (such advertisement is only performed with your permission where your consent is required by applicable law)</li>
            <li>To diagnose problems with our servers or our Services;</li>
            <li>In connection with our security and compliance programs;</li>
            <li>To administer our websites;</li>
            <li>To communicate with you;</li>
            <li>To target prospective customers with our products or services (such targeting is only performed with your permission where your consent is required by applicable law);</li>
            <li>To assist us in offering you a personalized experience or otherwise tailor our Services to you; and</li>
            <li>As otherwise described in this privacy policy.</li>
          </ul>
          <p>We also use the information we receive in aggregated and anonymized formats to produce reports on trends and statistics, such as mobile search trends, email open rates by industry, campaign best practices or the number of users that have been exposed to, or clicked on, our websites or evaluated or purchased our products and services.</p>
        <p>Payment information that you provide to us, including credit card data, will only be used to facilitate payment for our products and services, through our third-party site. We will use your payment information to process your order and deliver the products or services and fulfil our contract with you. We record your IP address in order to show that the correct tax was applied to the sale, which we are required to do by law. Your credit card details are passed to a third-party payment processor which is based in the USA and is certified to the EU-US Privacy Shield. We do not retain your credit card information.</p>
        <p>We do not use your personal data for the purposes of automated decision-making or profiling. If we do so in the future, we will inform you of any such processing and provide you with an opportunity to object.</p>
        </div>

      <div className="terms-popup__block">
        <h3>SHARING OF INFORMATION</h3>
        <p>We will only share certain personal information as further described below.</p>
        <p>Sharing with Partners</p>
        <p>When we share personal information with certain third-party partners, including marketing and advertising partners, that information includes your name, email address and other information enabling partners to:</p>
        <ul>
          <li>assist you in using our products and services,</li>
          <li>contact you with offers, services or products that may be of interest to you, and provide you with their products or services.</li>
        </ul>
        <p>If you are located in a jurisdiction where such sharing requires your permission, we will only do so with your consent. Please note that if you access our services through a tool that hides your location, such as through a virtual private network, you may not receive our request for permission because we were not able to identify you as being located in a jurisdiction where your permission is required.</p>
        <p>Further, our partners are prohibited from using your contact information for any purpose beyond those set forth above without your consent. We will not provide our partners with your credit card information. Further information about certain partners we share personal information with is available here.</p>
        <p>In the event we collect information from you in connection with an offer that is jointly presented by us and a partner, we will let you know who is collecting the information and whose privacy policy applies, as well as any options you may have regarding use of your information.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Sharing with Third Party Service Providers and Vendors</h3>
        <p>Occasionally, we enter into contracts with carefully selected third parties so that they can assist us in servicing you (for example, providing you with customer service, fraud detection and deterrence or access to advertising assets and providing us with information technology and storage services) or to assist us in our own marketing and advertising activities (including providing us with analytic information and search engine optimization services). Additional information about certain third-party service providers we share personal information with is available here. Our contracts with such third parties prohibit them from using any of your personal information for any purpose beyond the purpose for which it was shared.</p>
        <p>If you purchase a product or service from a third party through our site, we will pass your personal information to such third party in order for them to fulfill your order.</p>
        <p>When you enable subtitles for videos you upload in iLaugh App, you agree that sound from your video will be processed by Google Cloud Services and may be used by them to improve their services.</p>
        <p>We also share non-personal information with certain third parties, including the media, industry observers, marketing and advertising partners, vendors, customers, potential customers or partners. For example, we disclose mobile search trends, email open rates by industry, campaign best practices or the number of users that have been exposed to, or clicked on, our websites or evaluated or purchased our products and services.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Corporate Reorganizations</h3>
        <p>If we are involved in a merger, acquisition, a sale of all or a substantial portion of our assets, or other similar sale transaction, your information will be transferred as part of that transaction. We will notify you by email and/or a prominent notice on our website of any such transfer and any choices you may have regarding your information.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Authorized Users</h3>
        <p>All users authorized by you to have access to your account can view personal information stored in the account. A primary account holder can view personal information saved in subaccounts to which they have authorized access. We share information about authorized users only for legitimate purposes consistent with this privacy policy, including servicing your account and marketing products and services to you.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Legal Process</h3>
        <p>Lastly, if legally required to do so, or if we have a good faith belief that such disclosure is reasonably necessary, we may disclose your personal information to courts of law, law enforcement authorities and other relevant third parties, such as internet service providers, to conduct an investigation, respond to a third party or law enforcement subpoena or court order, bring legal action, prevent harm to others or pursue other relief when you or a third party are or may be:</p>
        <ul>
          <li>violating our terms and conditions of use;</li>
          <li>causing injury or other harm to, or otherwise violating the property or other legal rights, of us, other users, or third parties;</li>
          <li>violating federal, state, local, or other applicable law.</li>
        </ul>
        <p>This disclosure can include transferring your information to the U.S. and other countries outside the European Economic Area. To the extent we are legally permitted to do so, it is our policy to notify you in the event that we are required to provide your personal information to third parties in connection with a subpoena.</p>
      </div>
      <div className="terms-popup__block">
        <h3>SECURITY</h3>
        <p>The transmission of information via the internet, email or text message is not completely secure.</p>
        <p>Although we will do our best to protect your personal information, we cannot guarantee the security of your information transmitted through the Services or over email; any transmission is at your own risk. Once we have received your information, we will take appropriate technical and organizational measures to safeguard your personal information against loss, theft and unauthorized use, access or modification.</p>
        <p>When we collect financial account information, such as credit card numbers, we protect its transmission through the use of encryption such as the Transport Layer Security (TLS) protocol. CHOICE/OPT-IN</p>
      </div>
      <div className="terms-popup__block">
        <h3>Email</h3>
        <p>You have the opportunity, through our site to choose whether you wish to receive marketing/exclusive content communications (text or emails). You are required to provide one form of communication (text or email) for administrative communications. You may change your preferences by following a link in the footer of all non-transactional email messages from us or by emailing us at support@ilaugh.co. Some communications from us are considered transactional or service communications (for example, important account notifications and billing information), and your account(s) for our products and services are provided to you upon the condition that you receive these communications from us. You must cancel your account(s) for our products and services, as applicable, if you do not wish to receive any transactional or service communications. To cancel your account(s), please follow the instructions found in the terms of service for our service. You may still receive marketing communications from us even after you cancel your account unless you also opt-out of our marketing communications, as described above. Notwithstanding the foregoing, you may receive email communications from providers on our Site that are generated directly by such providers outside of our Site. If you do not wish to receive any such emails, please contact the provider directly.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Phone</h3>
        <p>We may contact you by telephone, with your consent where applicable, for marketing purposes (including by automatic dialer and/or prerecorded message). If you do not want to receive marketing calls, please contact us at support@ilaugh.co. You do not need to agree to receive automated marketing phone calls or texts from us to use the Services.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Text Messages</h3>
        <p>We may contact you by automated text message, with your consent where applicable, for marketing purposes. Message and data rates may apply If you do not want to receive marketing text messages, please either reply “STOP” to the Veeps text message, (Message and data rates may apply) remove your telephone number from your online account, or contact us at support@ilaugh.co.</p>
        <p>Message frequency may vary. You do not need to agree to receive automated marketing texts from us to use the ticketing Services. By consenting to use our services you agree to update your mobile number as changes occur.</p>
        <p>For subscriptions you are consenting to your artist you have subscribed to from the Veeps platform contacting you via autodialed calls, automated SMS messages, and/or pre-recorded messages at the wireless number provided regarding artist updates, content, upcoming events, and information about releases, announcements and sales. Your consent may be withdrawn by pausing your service or canceling your account. Message and Data Rates Apply. Message frequency may vary. If you have any questions, please contact us at support@ilaugh.co or Garrynamann, Kells, Kilkenny, Ireland.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Information from Third Parties</h3>
        <p>To manage the information we receive about you from a social networking site or other third parties (if applicable), you will need to follow the instructions from that party for updating your information and changing your privacy settings, where available. If you are located in a jurisdiction where such receipt requires your permission and we receive such information from any third parties, we will ensure that such third parties have your consent to share such information. The information we collect is covered by this privacy policy and the information the third party collects is subject to such third party’s privacy practices. Privacy choices you have made on any third party site will not apply to our use of the information we have collected directly through our Services.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Unauthorized Accounts</h3>
        <p>If an account or profile was created without your knowledge or authorization, please contact customer support at the brand on which the account or profile is located to request removal of the account or profile.</p>
      </div>
      <div className="terms-popup__block">
        <h3>RETENTION OF PERSONAL INFORMATION</h3>
        <p>We retain your personal information, including your phone number and your e-mail address, to provide services to you and as otherwise necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will retain your personal information for no more than seven years following the later of (i) the date on which you terminate your use of the Services or (ii) August 20, 2020, unless we are otherwise required by law or regulation to retain your personal information for longer.</p>
      </div>
      <div className="terms-popup__block">
        <h3>YOUR RIGHTS</h3>
        <p>Where the European Union’s General Data Protection Regulation 2016/679, or GDPR, applies, in certain circumstances and subject to data processing agreements, you have rights in relation to the personal information we hold about you. We set out below an outline of those rights and how to exercise those rights. Please note that we will require you to verify your identity before responding to any requests to exercise your rights by providing details only known to the account holder. Please note that for each of the rights below we may have valid legal reasons to refuse your request, in such instances we will let you know if that is the case.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Access</h3>
        <p>You have the right to know whether we process personal information about you, and if we do, to access data we hold about you and certain information about how we use it and who we share it with.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Portability</h3>
        <p>You have the right to receive a subset of the personal information you provide us if we process it on the legal bases of our contract with you or with your consent in a structured, commonly used and machine-readable format and a right to request that we transfer such personal information to another party. If you wish for us to transfer the personal information to another party, please ensure you detail that party and note that we can only do so where it is technically feasible. We are not responsible for the security of the personal information or its processing once received by the third party.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Correction</h3>
        <p>You have the right to require us to correct any personal information held about you that is inaccurate and have incomplete data completed. Where you request correction, please explain in detail why you believe the personal information we hold about you to be inaccurate or incomplete so that we can assess whether a correction is required. Please note that while we assess whether the personal information we hold about you is inaccurate or incomplete, you may exercise your right to restrict our processing of the applicable data as described below.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Erasure</h3>
        <p>You may request that we erase the personal information we hold about you in the following circumstances:</p>
        <ul>
          <li>where you believe it is no longer necessary for us to hold the personal information;</li>
          <li>we are processing it on the basis of your consent and you wish to withdraw your consent;</li>
          <li>we are processing your data on the basis of our legitimate interest and you object to such processing;</li>
          <li>you no longer wish us to use your data to send you marketing; or</li>
          <li>you believe we are unlawfully processing your data.</li>
        </ul>
      </div>
      <div className="terms-popup__block">
        <h3>Restriction of Processing to Storage Only</h3>
        <p>You have a right to require us to stop processing the personal information we hold about you other than for storage purposes in the following circumstances:</p>
        <ul><li>You believe the personal information is not accurate for the period it takes for us to verify whether the data is accurate;</li><li>We wish to erase the personal information as the processing we are doing is unlawful but you want us to simply restrict the use of that data;</li><li>We no longer need the personal information for the purposes of the processing but you require us to retain the data for the establishment, exercise or defense of legal claims; or</li><li>You have objected to us processing personal information we hold about you on the basis of our legitimate interest and you wish us to stop processing the personal information while we determine whether there is an overriding interest in us retaining such personal information.</li></ul>
      </div>
      <div className="terms-popup__block">
        <h3>Objection</h3>
        <p>You have the right to object to our processing of data about you and we will consider your request. Please provide us with detail as to your reasoning so that we can assess whether there is a compelling overriding interest in us continuing to process such data or we need to process it in relation to legal claims.</p>
        <p>You also have the right, at any time, to object to our processing of data about you in order to send you marketing, including where we build profiles for such purposes and we will stop processing the data for that purpose.</p>
      </div>
      <div className="terms-popup__block">
        <h3>Withdrawal of Consent</h3>
        <p>Where you have provided your consent to us processing your personal data, you can withdraw your consent at any time by emailing us at support@ilaugh.co THIRD PARTY LINKS AND INFORMATION COLLECTION AND USE BY OUR USERS Some of our Services provide links to other websites. Because we do not control the information policies or practices of these third party sites, you should review their privacy policies to learn about how they collect and use personal information.</p>
        <p>You may also log in to some of our Services using sign-in services such as Facebook Connect or an Open ID provider. These services will authenticate your identity and provide you with the option to share certain personal information with us, such as your name and email address to pre-populate our sign up form. For example, if you take advantage of certain of our social media features, such as the Facebook Like button, and widgets, such as the “Share This” button or interactive mini-programs that run on our site, the applicable social networking sites may collect your IP address, which page you are visiting on our websites, and may set a cookie to enable it to function properly.</p>
      </div>
      <div className="terms-popup__block">
        <h3>CHILDREN</h3>
        <p>Our Services are not directed to persons under 18. We do not knowingly collect personal information from children under 18. If a parent or guardian becomes aware that his or her child has provided us with personal information without such parent or guardian’s consent, he or she should contact us. If we become aware that a child under 18 has provided us with personal information, we will delete such information from our files.</p>
      </div>
      <div className="terms-popup__block">
        <h3>DATA TRANSFER</h3>
        <p>In order for us to provide the Services to you, your personal information will be transferred to, and stored at/processed in the United States. Your personal data is also processed by staff operating outside the European Economic Area (EEA), such as in India, who work for us or for one of our suppliers. We will take all steps reasonably necessary to ensure that your personal data is treated securely and in accordance with this policy.</p>
        <p>For transfers of personal information within our corporate family, such transfer will be under the Commission’s model contracts for the transfer of personal data to third countries (i.e., the standard contractual clauses), pursuant to Decision 2004/915/EC and 2010/87/EU.</p>
        <p>For transfers of data to third parties, such transfers will either (i) be under the Commission’s model contracts for the transfer of personal data to third countries (i.e., the standard contractual clauses), pursuant to Decision 2004/915/EC and 2010/87/EU, or (ii) rely on the EU-US Privacy Shield Framework.</p>
        <p>You can contact the Data Protection Officer listed below to obtain a copy of the data transfer agreement or more information regarding the relevant safeguard we put in place. For more information about the EU-US Privacy Shield Framework, please visit the U.S. Department of Commerce’s Privacy Shield website.</p>
      </div>
      <div className="terms-popup__block">
        <h3>COMPLAINTS</h3>
        <p>In the event that you wish to make a complaint about how we process your personal information, please contact our Data Protection Officer at support@ilaugh.co and we will try to deal with your request. This is without prejudice to your right to raise a complaint with our European Local Representative at:</p>
        <p>Brendan Morrissey</p>
        <p>Garrynamann, Kells</p>
        <p>Kilkenny, Ireland</p>
        <p>+353863179000</p>
      </div>
      <div className="terms-popup__block">
        <h3>NOTIFICATION OF CHANGES</h3>
        <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. If we decide to change this privacy policy in any material way, we will notify you here, by email, or by means of a notice on www.ilaugh.org prior to the change becoming effective. In all cases, your continued use of any Services constitutes acceptance to any such changes.</p>
      </div>
    </div>
    </div>
    </>
  )
}

export default Terms
