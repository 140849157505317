import {combineReducers} from 'redux';
import {eventsReducer} from './events';
import {authReducer} from './auth';
import {globalReducer} from './global';
import {postsReducer} from './posts';
import {profileReducer} from './profile';
import {messagingReducer} from './messages'
import {paymentsReducer} from './payments'
import { connectRouter } from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  eventsReducer,
  authReducer,
  globalReducer,
  postsReducer,
  profileReducer,
  messagingReducer,
  paymentsReducer,
});
