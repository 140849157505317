import React from 'react'
import Popup from '../Popup'
import { useSelector, useDispatch } from 'react-redux'
import { globalActions } from '../../redux/actions'
import './SuccessPopup.css'

const SuccessPopup = () => {
  const { show, message } = useSelector(state => state.globalReducer.successPopup)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(globalActions.toggleSuccessModal({ open: !show, message: message }))
  }

  return (
    <Popup open={show} handleClose={handleClose} className="popup__block success__popup">
        <h2 className="success-popup__headline">{message}</h2>
    </Popup>
  )
}

export default SuccessPopup
