export const EVENTS_ACTIONS = {
  GET_EVENTS : 'GET_EVENTS',
  GET_EVENTS_SUCCESS : 'GET_EVENTS_SUCCESS',
  GET_EVENTS_ERROR : 'GET_EVENTS_ERROR',
  GET_UPCOMING_EVENTS : 'GET_UPCOMING_EVENTS',
  GET_UPCOMING_EVENTS_SUCCESS : 'GET_UPCOMING_EVENTS_SUCCESS',
  GET_UPCOMING_EVENTS_ERROR : 'GET_UPCOMING_EVENTS_ERROR',
  GET_EVENT : 'GET_EVENT',
  GET_EVENT_SUCCESS : 'GET_EVENT_SUCCESS',
  GET_EVENT_ERROR : 'GET_EVENT_ERROR',
  GET_PAST_EVENTS : 'GET_PAST_EVENTS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
}