import React, { useEffect }from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { eventsActions } from '../../redux/actions'
import Event from './Event'

const PastEvents = () => {
  const dispatch = useDispatch()
  const events = useSelector(state => state.eventsReducer)

  useEffect(() => {
    dispatch(eventsActions.getPastEvents())
  }, [])

  return (
    <div>
      <div class="events__list events__list--upcoming" id="upcoming">
        <div class="list">
            {events.events ? events.events.map((event, i) => <Event id={event.id} title={event.title} description={event.description} place={event.location} price={event.price} date={event.start_date} key={i} />) : null}
            {/* <h1>Past events</h1> */}
        </div>
      </div>
    </div>
  )
}

export default PastEvents
