import React from 'react'
import Button from './Button'
import { globalActions } from '../../redux/actions'
import { useDispatch } from 'react-redux'
 
const RegisterButton = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(globalActions.toggleRegisterModal(true, false))
  }
  return (
    <Button accentColor handleClick={handleClick}>Sign Up</Button>
  )
}

export default RegisterButton
