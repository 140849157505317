import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import moment from 'moment';

const ErrorMessage = ({children}) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);
const PaymentForm = ({
  event,
  user,
  buyTicket,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {handleSubmit, register, errors} = useForm();
  const [blockButton, setBlockButton] = useState(false);
  const [full_name, setFullName] = useState(user?.full_name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [error, setError] = useState('');

  const handleFullName = event => {
    const full_name = event.target.value;
    setFullName(full_name);
  };
  const handleEmail = event => {
    const email = event.target.value;
    setEmail(email);
  };
  const submitPayment = async data => {
    setBlockButton(true);
    if (!stripe || !elements) {
      setBlockButton(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (error && error.message) {
        setError(error.message);
        setBlockButton(false);
      } else {
        const payment = {
          stripe_email: data.email,
          paymentMethod,
          amount: (event?.price || 0) * 100,
          event_id: event?.id,
          stripe,
        };
        buyTicket(payment);
      }
    }
  };
  return (
    <div className="content">
      <section className="payment">
        <div className="site-width">
          <div className="payment__block">
            <div className="payment__header">
              <div className="payment__title">{event?.title}</div>
              <div className="payment__date">
                {moment(event?.start_date).format('ddd, MMM D, H:mm')}
              </div>
              <div className="payment__summ">{`To pay ${event?.price} EUR`}</div>
            </div>
            <div className="payment__content">
              <form className="form" onSubmit={handleSubmit(submitPayment)}>
                <div className="form__row">
                  <input
                    className="form__input"
                    type="text"
                    name="fullname"
                    placeholder="Your name"
                    value={full_name}
                    onChange={handleFullName}
                  />
                </div>
                <div className="form__row">
                  <input
                    className={`form__input ${
                      errors && errors.email ? 'form__input_error' : ''
                    }`}
                    type="email"
                    name="email"
                    {...register('email', {
                      required: 'Email Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email address',
                      },
                    })}
                    placeholder="Your Email"
                    value={email}
                    onChange={handleEmail}
                  />
                  {errors && errors.email && (
                    <span className="form__input_error">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="form__row">
                  <CardElement
                    options={{hidePostalCode: true}}
                    className="form__input"
                  />
                </div>
                <div className="form__row--submit">
                  <button
                    type="submit"
                    className="btns btn-gradient"
                    disabled={blockButton}>
                    Submit payment
                  </button>
                </div>
                {error && <ErrorMessage><p style={{color: 'black'}}>{error}</p></ErrorMessage>}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentForm;