import React, { useState, useEffect } from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import { profileActions } from '../../redux/actions'
import Popup from '../Popup'

const EditPopup = ({ open, handleClose }) => {
  // @todo add country change
  const user = useSelector(state => state.authReducer.user)
  const [country, setCountry] = useState('')
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm()
  
  useEffect(() => {
    setCountry(user?.country)
  }, [user?.country])

  const onSubmit = (data) => {
    const userToPut = {
      ...user,
      full_name: data.fullName,
      user_name: data.userName,
      // email: data.email,
      bio: data.bio,
      country: country ? country : null
     }

    dispatch(profileActions.updateProfile(userToPut))
    handleClose()
  }

  return user ? (
    <Popup open={open} handleClose={handleClose} className="profile-popup setting-popup edit-popup">
      
        <h1>Edit profile</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="profile-popup__inputs">
            <label>
              Full Name
              <input defaultValue={ user.full_name ? user.full_name : '' } type="text" placeholder="Full Name" { ...register("fullName") } />
            </label>
            <label>
              Username
              <input defaultValue={user.user_name ? user.user_name : '' } type="text" placeholder="Username" { ...register("userName", { required: true}) } />
            </label>
            <label>
              Country
              <CountryDropdown defaultValue={country} value={country ? country : undefined} onChange={(val) => setCountry(val)} className=" settings__country-selector" />
            </label>
            {/* <label>
              Email
              <input defaultValue={user.email ? user.email : '' } type="email" placeholder="Email" { ...register("email", { required: true}) } />
            </label> */}
            <label >
              About me
              <textarea defaultValue={user.bio ? user.bio : ''} { ...register("bio") } />
            </label>
          </div>
          <input type="submit"  value="SAVE" />
        </form>
    </Popup>
  ) : null
}

export default EditPopup