import React from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { globalActions, authActions } from '../../redux/actions'
import { useForm } from "react-hook-form";

// icons
import {ReactComponent as ArrowIcon} from '../../imgs/icons/i-arrow_left.svg'
import {ReactComponent as ViewIcon} from '../../imgs/icons/i-view.svg'
import {ReactComponent as HideIcon} from '../../imgs/icons/i-hide.svg'

import Popup from '../Popup'

const LoginPopup = () => {
	const showLoginModal = useSelector(state => state.globalReducer.showLoginModal)
	const error = useSelector(state => state.globalReducer.error)
	const { register, handleSubmit, formState: { errors } } = useForm();
	const dispatch = useDispatch()


  const handlePopupClose = e => {
    dispatch(globalActions.toggleLoginModal(false, false))
  }

	const onSubmit = data => {
		dispatch(authActions.login(data.email, data.password))
		// dispatch(authActions.login('laduhaboss@gmail.com', '123456vL'))
	}
	const showLogin = localStorage.getItem('event');


  // console.log(showLoginModal)
  return (
		<Popup open={showLoginModal || showLogin} handleClose={handlePopupClose} btnClass="btns btn-closepopup" className="popup__block popup__signin">
				<div className="popup__header">
					<div className="popup__title">Welcome back</div>
					<div className="popup__text">Login to your account</div>
				</div>
				<div className="popup__content">
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="form__row">
							<input type="text" className="form__input" placeholder="Email" { ...register('email', { required: true }) } />
						</div>
						<div className="form__row  form__row--password form__row--icon">
							<input type="password" className="form__input" placeholder="Password" { ...register('password', { required: true }) } />
							{/* <button className="btns btn-showpass">
								<ViewIcon className="icons svg i40x40" />
							</button>
							<button className="btns btn-hidepass">	
								<HideIcon className="icons svg i40x40" />
							</button> */}
						</div>
						<div className="form__row form__row--link">
							{/* <button className="btns btn-showpopup" data-target=".popup__forgot">Forgot your password?</button> */}
						</div>
						<div className="form__row form__row--submit">
							<button type="submit" className="btns btn-grdnt btn-submit">LOGIN</button>
							{ error && <p className="auth-error">{error}</p> }
						</div>
					</form>
				</div>
		</Popup>
  )
}

export default LoginPopup
