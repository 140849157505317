import React from 'react'

const AppWrapper = ({children, oveflow = 'hidden'}) => {
  return (
    <div
      style={{
        backgroundColor: '#030405',
        height: '100%',
        overflow: oveflow, 
        width: '100%',
        paddingBottom: '5vh',
      }}
    >
      {children}
    </div>
  )
}

export default AppWrapper
