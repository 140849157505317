import React from 'react'
import Portal from './Portal'
import {ReactComponent as ArrowIcon} from '../imgs/icons/i-arrow_left.svg'


const Popup = ({ open = false, children, handleClose, className, args, hideArrow = false }) => {
  const handleCloseModal = e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    handleClose()
  }

  const stopPropagation = e => {
    e.stopPropagation()
  }

  return open ? (
    <Portal>
      <section id="popups" className="popup__main" { ...args }>
        <div onClick={ handleClose ? e => handleCloseModal(e) : null } className="popup__overlay">
          <div onClick={stopPropagation} className={className}>
            { !hideArrow && <ArrowIcon onClick={handleCloseModal} className={`icons svg i40x40 arrow-back-icon`} /> }
            { children }
          </div>
        </div>
      </section>
    </Portal>
  ) : null
}

export default Popup