import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { profileActions, paymentActions } from '../../redux/actions'
import { Link } from 'react-router-dom'
import { config } from '../../config'
import moment from 'moment'

import emojiImage from '../../imgs/emoji_small.png'
import { ReactComponent as VerifiedIcon } from '../../imgs/verified.svg'
import { ReactComponent as TicketIcon } from '../../imgs/icons/i-ticket.svg'

const PostInfo = ({ post }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  let avatar = post.user.avatar_thumb_url

  const handleFollow = () => {
    dispatch(profileActions.follow(post.user.id))
  }

  const handleUnfollow = () => {
    dispatch(profileActions.unfollow(post.user.id))
  }

  const handleTip = () => {
    dispatch(paymentActions.toggleDonateModal(post.user))
  }

  return post ? (
    <div className="post-info__footer">
       {
          post.ticket_link &&
          <a href={post.ticket_link} className="ticket-link" target="_blank" ><TicketIcon className="icons i38x38 svg" /> <button className="post-info__ticket cursor--pointer">GET TICKETS</button></a> }
      <div className="post-info__body">
        <Link to={`/users/${post.user.id}`}><div style={{ backgroundImage: `url(${avatar ? config.REACT_APP_IMAGE_URL + avatar : emojiImage })`}} className="post__avatar"></div></Link>
      <div className="post__desc">
        
        <div className="post-info__headline">
          <div className="post-info__user">
            <Link to={`/users/${post.user.id}`}><h3>@{post.user.user_name} {post.user.verified && <VerifiedIcon className="icons i22x22 svg" />}</h3></Link>
            <div className="post-info__btns">
              { post.user.verified && <button onClick={handleTip} className="post-info__tip">tip</button> }
              
                {
                  post.is_followed ? <button onClick={handleUnfollow} className="post-info__follow followed">following</button> : <button onClick={handleFollow} className="post-info__follow">follow</button>
                }
            </div>
          </div>
          <span className="post-info__date">{moment(post.created_at).fromNow()}</span>
        </div>
        <div className="post-info__text">
          <p className="post-info__info">{post.body}</p>
          {/* <ul className="post-info__hashtags">
            {post.hashtags.length ? post.hashtags.map(hash => (<li key={hash.id}>#{hash.title}</li>)) : null }
          </ul> */}
        </div>
      </div>
      </div>
    </div>
  ) : null
}

export default PostInfo
