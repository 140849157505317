import React, {useEffect} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import PaymentForm from '../components/PaymentForm';
import Header from '../components/Header'
import Footer from '../components/LandingFooter';
import AppWrapper from '../components/AppWrapper';
import {config} from '../config';

const stripePromise = loadStripe(config.STRIPE_API_KEY);

const BuyTicket = ({
  getEvent,
  event,
  location,
  user,
  buyTicket,
}) => {
  useEffect(() => {
    const {pathname} = location;
    const eventID = localStorage.getItem('event');
    const id = pathname.replace('/buy-ticket/', '');
    getEvent(id);
    if (eventID) {
      localStorage.removeItem('event');
    }
  }, []);
  useEffect(() => {
    const {pathname} = location;
    const id = pathname.replace('/buy-ticket/', '');
    getEvent(id);
  }, [location]);
  return (
    <AppWrapper>
      <div className="page__wrapper_landing">
        <Header />
        <Elements stripe={stripePromise}>
          <PaymentForm event={event} user={user} buyTicket={buyTicket} />
        </Elements>
        <Footer />
      </div>
    </AppWrapper>
  );
};

export default BuyTicket;