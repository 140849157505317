import {takeEvery, put, call, select} from 'redux-saga/effects';
import {MESSAGING_CONSTS} from '../constants';
import {authActions, messagingActions} from '../actions';
import {processRequest} from '../../services/Api';
import {getUserSelector} from '../selectors/auth';
import {filterBlackList} from '../../helpers';
import {sendMessage} from '../actions/messagingActions';
// import { appHistory } from '../../services/HistoryConfig'
import { ROUTES } from '../../constants/ROUTES';
import { push } from 'connected-react-router'


function* handleGetChats(action) {
  try {
    const {
      payload: {meta},
    } = action || {};
    const {page, perPage, search} = meta || {};
    const getChatsSuccess =
      page === 1
        ? messagingActions.getChatsSuccess
        : messagingActions.getChatsNextPageSuccess;
    const {data} = yield call(
      processRequest,
      `/chats?page=${page}&per_page=${perPage || 10}&query=${
        search || ''
      }`,
      'GET',
    );

    if (data.chats) {
      const user = yield select(getUserSelector);
      const chats = filterBlackList({
        chats: data.chats,
        blackList: user.i_block_user_ids || [],
        blockedMe: user.me_block_user_ids || [],
      });
      // const chats = data.chats.filter((chat) => chat.last_message);
      yield put(getChatsSuccess(chats, data.total_pages));
      if (data.chats.length === 10 && chats.length < 10)
        yield put(messagingActions.getChats({page: page + 1}));
    } else {
      yield put(messagingActions.getChatsError(data));
    }
  } catch (e) {
    console.log(e);
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.getChatsError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.getChatsError(statusText));
    } else if (e.message) {
      yield put(messagingActions.getChatsError(e.message));
    } else {
      yield put(messagingActions.getChatsError('Internal server error.'));
    }
  }
}

function* handleGetMessages(action) {
  try {
    const {
      payload: {messagesMeta},
    } = action || {};
    const {chat_id, date} = messagesMeta || {};
    const {data} = yield call(
      processRequest,
      `/messages/?chat_id=${chat_id}${date?`&date=${date}`:''}`,
      'GET',
    );

    if (data.messages) {
      yield put(messagingActions.getMessagesSuccess(data.messages));
    } else {
      yield put(messagingActions.getMessagesError(data));
    }
  } catch (e) {
    console.log(e);

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.getMessagesError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.getMessagesError(statusText));
    } else if (e.message) {
      yield put(messagingActions.getMessagesError(e.message));
    } else {
      yield put(messagingActions.getMessagesError('Internal server error.'));
    }
  }
}

function* handleSendMessageFromModal(action) {
  try {
    const {
      payload: {user_id, message},
    } = action || {};
    const requestPayload = {user_id};
    const {data} = yield call(
      processRequest,
      `/chats/create`,
      'POST',
      requestPayload,
    );
    if (data.chat) {
      const {chat, messages} = data;

      yield put(messagingActions.createChatSuccess(chat, messages || []));
      const newMessageData = {chat_id: chat.id, body: message};
      yield put(sendMessage(newMessageData));
      yield put(messagingActions.createChat(user_id));
      yield put(messagingActions.toggleMessagePopup());
      yield put(push(ROUTES.CHATS));
      // yield put(appHistory.push(ROUTES.CHATS))
    } else {
      yield put(messagingActions.createChatError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.createChatError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.createChatError(statusText));
    } else if (e.message) {
      yield put(messagingActions.createChatError(e.message));
    } else {
      yield put(messagingActions.createChatError('Internal server error.'));
    }
  }
}

function* handleCreateChat(action) {
  try {
    const {
      payload: {user_id},
    } = action || {};
    const currentUser = yield select(getUserSelector);
   
    if (
      currentUser.me_block_user_ids &&
      currentUser.me_block_user_ids.indexOf(currentUser.id) !== -1
    ) {
      return;
    } else {
      const requestPayload = {user_id};
      const {data} = yield call(
        processRequest,
        `/chats/create`,
        'POST',
        requestPayload,
      );

      if (data.chat) {
        const {chat, messages} = data;

        yield put(messagingActions.createChatSuccess(chat, messages || []));
        // yield call(RootNavActions.back);
        } else {
          yield put(messagingActions.createChatError(data));
        }
    }
  } catch (e) {

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.createChatError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.createChatError(statusText));
    } else if (e.message) {
      yield put(messagingActions.createChatError(e.message));
    } else {
      yield put(messagingActions.createChatError('Internal server error.'));
    }
  }
}

function* handleSendMessage(action) {
  try {
    const {
      payload: {message},
    } = action || {};
    const {chat_id, body, picture} = message || {};
    const formData = new FormData();

    formData.append('chat_id', chat_id);

    if (body) formData.append('body', body);

    if (picture && picture.url)
      formData.append('picture', {
        uri: picture.url,
        name: picture.url,
        type: picture.type,
        filename: picture.fileName,
      });

    const {data} = yield call(
      processRequest,
      `/chats/send_message`,
      'POST',
      formData,
      false,
    );

    if (data.message) {
      yield put(messagingActions.sendMessageSuccess(data.message));
    } else {
      yield put(messagingActions.sendMessageError(data));
    }
  } catch (e) {
    console.log(e);

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.sendMessageError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.sendMessageError(statusText));
    } else if (e.message) {
      yield put(messagingActions.sendMessageError(e.message));
    } else {
      yield put(messagingActions.sendMessageError('Internal server error.'));
    }
  }
}

function* handleDeleteMessage(action) {
  try {
    const {
      payload: {message},
    } = action || {};
    const {id} = message || {};
    const {data} = yield call(
      processRequest,
      `/chats/delete_message?message_id=${id}`,
      'DELETE'
    );

    if (data.data.mesage) {
      yield put(messagingActions.deleteMessageSuccess({id}));
    } else {
      yield put(messagingActions.deleteMessageError(data));
    }
  } catch (e) {

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.deleteMessageError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.deleteMessageError(statusText));
    } else if (e.message) {
      yield put(messagingActions.deleteMessageError(e.message));
    } else {
      yield put(messagingActions.deleteMessageError('Internal server error.'));
    }
  }
}

export function* watchMessagingSagas() {
  yield takeEvery(MESSAGING_CONSTS.GET_MESSAGES, handleGetMessages);
  yield takeEvery(MESSAGING_CONSTS.GET_CHATS, handleGetChats);
  yield takeEvery(MESSAGING_CONSTS.CREATE_CHAT, handleCreateChat);
  yield takeEvery(MESSAGING_CONSTS.SEND_MESSAGE, handleSendMessage);
  yield takeEvery(MESSAGING_CONSTS.DELETE_MESSAGE, handleDeleteMessage);
  yield takeEvery(
    MESSAGING_CONSTS.SEND_MESSAGE_FROM_MODAL,
    handleSendMessageFromModal,
  );
}
