import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { paymentActions } from '../../redux/actions'
import Popup from '../Popup'

const DonatePopup = () => {
  const [successfulDonation, setSuccessulDonation] = useState(false)
  const stripe = useStripe();
  const paymentsReducer = useSelector(state => state.paymentsReducer)
  const { donateModalVisible: open, donateModal, isProcessing, paymentIntent } = paymentsReducer
  const { user } = donateModal
  const { user_name: userName, full_name: fullName, id } = user || {};
  const dispatch = useDispatch()

  const [price, setPrice] = useState({
    active: 'btn-2',
    price: 500,
  })

  const [message, setMessage] = useState('')

  const elements = useElements(CardElement);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const billingDetails = {
      amount: price.price,
      user_id: id,
      message: message
    }
    

    // useEffect(() => {
      

    // }, [paymentIntent])
    // const peymentMethodReq = await  stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement
    // })
    // console.log(peymentMethodReq)

    dispatch(paymentActions.donate({ billingDetails , cardElement, stripe }))

    console.log(billingDetails);
  }

  const stopPropagation = e => {
    e.stopPropagation();
  }

  const handleCloseModal = () => {
    dispatch(paymentActions.toggleDonateModal())
  }

  const handlePrice = e => {
    if(e.target.value) {
      setPrice(prevPrice => ({
        active: e.target.name,
        price: e.target.value * 100
      }))
    }
  }

  const style = {
    base: {
      fontSize: '18px',
      color: '#424770',
      lineHeight: '40px',
      borderRadius: '10px',
      '::placeholder': {
        color: '#aab7c4',
      },
      backgroundColor: '#f1f3f6',
      padding: '10% 10%',
    },
    invalid: {
      color: '#9e2146',
    },
  }

  return (
    <Popup open={open} handleClose={handleCloseModal} className="donate__popup" >
        <div className="profile-popup" >
          { successfulDonation ? <h1>Successfull donation</h1> :
          <form onSubmit={handleSubmit} onClick={stopPropagation} className="profile-popup__form">
            <div className="donate">
              <h2 className="donate__headline">How much you want to donate to {fullName || userName}?</h2>
              <div className="donate__prices">
                <button
                  type="button"
                  onClick={handlePrice}
                  name="btn-1" 
                  value="1"
                  className={ `donate__price ${price.active === 'btn-1' ? 'active' : ''}`}>
                    1 €
                </button>
                <button 
                  type="button"
                  onClick={handlePrice}
                  name="btn-2"
                  value="5"
                  className={ `donate__price ${price.active === 'btn-2' ? 'active' : ''}`}>5 €</button>
                <button
                  type="button"
                  onClick={handlePrice}
                  name="btn-3" 
                  value="15"
                  className={ `donate__price ${price.active === 'btn-3' ? 'active' : ''}`}>
                    15 €
                </button>
              </div>
              <input 
                onClick={handlePrice}
                onChange={handlePrice}
                name="input-1" 
                className={ `donate__price-field ${price.active === 'input-1' ? 'active' : ''}`}
                type="number" min="1" step="any" placeholder="enter price manually" 
                />
              <div  className="card-wrapper">
                <CardElement options={{ style, hidePostalCode: true }} />
              </div>
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="donate__message" type="text" name="messsage" placeholder="Additional message"/>
              <input type="submit" disabled={isProcessing} className="donate__btn" value={isProcessing ? 'Proccessing payment...' : 'Continue'} />
						</div>
          </form> }
        </div>
    </Popup>
  )
}

export default DonatePopup