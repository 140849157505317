import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BuyTicket from '../pages/BuyTicket';
import {eventsActions, paymentActions} from '../redux/actions';

const mapStateToProps = state => ({
  user: state.authReducer.user,
  event: state.eventsReducer.event,
});

const mapDispatchToProps = dispatch => ({
  getEvent: bindActionCreators(eventsActions.getEvent, dispatch),
  buyTicket: bindActionCreators(paymentActions.buyTicket, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyTicket);