import {AUTH_CONSTS, LOCAL_STORAGE} from '../constants';
import { ROUTES } from '../../constants/ROUTES';
import {authActions, globalActions, profileActions} from '../actions';
import {processRequest} from '../../services/Api';
import { put, call, takeEvery } from 'redux-saga/effects'

import { appHistory } from '../../services/HistoryConfig'

import { useHistory } from 'react-router-dom'
import {setToken, getToken } from '../../services/LocalStorage'

function* handleIsAuthenticated() {
  try {
    const userJSON = localStorage.getItem(LOCAL_STORAGE.USER);
    const token = getToken(LOCAL_STORAGE.AUTH_TOKEN);
    if(token) yield put(profileActions.getCurrentUser());
    if (userJSON && token) {
      const user = JSON.parse(userJSON);
      yield put(authActions.loginSuccess(user));
      yield put(authActions.stepSet(1))
    }
  } catch (e) {
    console.log(e);
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(authActions.loginError(message));
    } else if (status === 401) {
      yield put(authActions.loginError(error));
    } else if (status === 500) {
      yield put(authActions.loginError(statusText || 'Internal server error.'));
    } else if (e.message) {
      yield put(authActions.loginError(e.message));
    } else {
      yield put(authActions.loginError('Internal server error.'));
    }
  }
}

function* handleLogin(action) {
  try {
    const {payload} = action;
    const {email, password} = payload;
    const requestPayload = {user: {email, password}};
    const {data} = yield call(
      processRequest,
      '/autorization/login',
      'POST',
      requestPayload,
    );
    if (data.data) {
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(data.data.user));
      setToken(
        LOCAL_STORAGE.AUTH_TOKEN,
        data.data.token.auth_token,
      );

      yield call(appHistory.push, ROUTES.FEED)
      yield put(authActions.loginSuccess(data.data.user));
    }
  } catch (e) {
    const {response} = e || {};
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(authActions.loginError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(authActions.loginError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(authActions.loginError(statusText || 'Internal server error.'));
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(authActions.loginError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(authActions.loginError('Internal server error.'));
    }
  }
}

function* handleSignUp(action) {
  try {
    const {
      payload: {formData},
    } = action || {};
    const {email, password} = formData;
    const requestPayload = {
      user: {
        email: email.toLowerCase(),
        password,
      },
    };

    const {data} = yield call(
      processRequest,
      '/users',
      'POST',
      requestPayload,
    );
    if (data.data && data.data.token) {
      const {token, user} = data.data;
      const jsonUser = JSON.stringify({user});
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(user));
      setToken(
        LOCAL_STORAGE.AUTH_TOKEN,
        token.auth_token,
      );

      yield put(authActions.stepAdd())
      yield put(authActions.signUpSuccess({email, password}));
      // yield call(AsyncStorage.setItem, LOCAL_STORAGE.TOKEN, token.auth_token);
      // yield call(AsyncStorage.setItem, LOCAL_STORAGE.USER, jsonUser);
      // yield put(authActions.nextStep(user, ROUTES.USERNAME_PAGE));
      // yield put(globalActions.toggleLoading(false));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];
        if (keys[0] === 'password') {
          message = "The password field can't be blank";
        } else {
          message =
            error_messages && keys[0] === 'email'
              ? `${errorMessage}`
              : `${keys[0]} ${errorMessage}`;
        }
      }

      yield put(authActions.signUpError(message));
    } else if (status === 401) {
      yield put(authActions.signInError(error));
    } else if (status === 500) {
      yield put(authActions.signUpError(statusText));
    } else if (e.message) {
      yield put(authActions.signUpError(e.message));
    } else {
      yield put(authActions.signUpError('Internal server error.'));
    }
  }
}

function* handleLogout() {
  try {
    // const {data} = yield call(processRequest, '/authentication/logout', 'PUT');
    // if (data.data.mesage === 'Success') {
    localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE.USER);
    yield put(authActions.logoutSuccess());
    // }
  } catch (e) {
    const {response} = e || {};
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(authActions.logoutError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(authActions.logoutError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(
        authActions.logoutError(statusText || 'Internal server error.'),
      );
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(authActions.logoutError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(authActions.logoutError('Internal server error.'));
    }
  }
}

export function* watchAuthSagas() {
  yield takeEvery(
    AUTH_CONSTS.AUTH_ACTIONS.IS_AUTHENTICATED,
    handleIsAuthenticated,
  );
  yield takeEvery(AUTH_CONSTS.AUTH_ACTIONS.SIGN_UP, handleSignUp);
  yield takeEvery(AUTH_CONSTS.AUTH_ACTIONS.LOGIN, handleLogin);
  yield takeEvery(AUTH_CONSTS.AUTH_ACTIONS.LOGOUT, handleLogout);
}