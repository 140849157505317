import * as EVENTS_CONSTS from './actions/events';
import * as POSTS_CONSTS from './actions/posts';
import * as PROFILE_CONSTS from './actions/profile';
import * as AUTH_CONSTS from './actions/auth';
import * as GLOBAL_CONSTS from './actions/global';
import * as PAYMENTS_CONSTS from './actions/payments';
import MESSAGING_CONSTS from './actions/messagingActions'
import * as INITIAL_STATE from './initialState';

console.log(MESSAGING_CONSTS)

export const LOCAL_STORAGE = {
  AUTH_TOKEN: 'ilaugh_token',
  USER: 'ilaugh_user',
};

export {
  EVENTS_CONSTS,
  POSTS_CONSTS,
  PROFILE_CONSTS,
  AUTH_CONSTS,
  GLOBAL_CONSTS,
  PAYMENTS_CONSTS,
  INITIAL_STATE,
  MESSAGING_CONSTS,
};