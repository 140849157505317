import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { messagingActions } from '../../redux/actions'
import { Link, useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment'

import emojiImage from '../../imgs/emoji_small.png'
import { config } from '../../config'

const MessagesRooms = () => {
  const messagesReducer = useSelector(state => state.messagingReducer)
  const { chats, chat: currentChat, meta } = messagesReducer
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(messagingActions.getChats({...meta, page: 1}))
  }, [])

  useEffect(() => {
    if(chats.length) {
      dispatch(messagingActions.createChat(chats[0].opponent.id))
    }
  }, [chats.length])

  const handleRoomClick = (id) => {
    dispatch(messagingActions.createChat(id))
  }

  const fetchMore = () => dispatch(messagingActions.getChats({...meta, page: meta.page + 1 }))
  
  return (
    <div className="message-rooms" id="messageRooms">
      <InfiniteScroll dataLength={chats?.length} hasMore={meta.page < meta.total_pages} next={fetchMore} scrollableTarget="messageRooms" styles={{ overflowX: 'hidden' }}>
      { 
        chats.map(chat => {
        let img = chat.opponent.avatar_url
        console.log(chat.opponent)
        const sentGif = chat.last_message?.body?.includes('[gif]');
        return ( 
        <div onClick={() => handleRoomClick(chat.opponent.id)} 
        className={`message-room ${currentChat.id === chat.id ? 'active' : ''}`}>
          <Link to={`/users/${chat.opponent.id}`}><div style={{ backgroundImage: `url(${img ? config.REACT_APP_IMAGE_URL + img : emojiImage})` }} className="message-room__avatar"></div></Link>
          <div className="message-room__text">
            <div className="message-room__upper-text">
              <h4 className="message-room__user">{chat.opponent.user_name}</h4>
              <span className="message-room__date">{moment(chat.updated_at).fromNow()}</span>
            </div>
            <p className="message-room__message">{sentGif&& 'Sent a GIF' || chat.last_message?.body || ''}</p>
          </div> 
        </div>
        )})
      }
      </InfiniteScroll>
    </div>
  )  
}

export default MessagesRooms
