import {takeEvery, put, call, select} from 'redux-saga/effects';
import {POSTS_CONSTS} from '../constants';
import {postsActions, profileActions} from '../actions';
import {filterBlackList} from '../../helpers';
import {getUserSelector} from '../selectors/auth';
import {processRequest} from '../../services/Api';

function* handleGetPosts(action) {
  try {
    const {payload} = action;
    const {meta} = payload;
    const {page, perPage, search, tab} = meta || {};
    const query = `/posts?page=${page}&per_page=${perPage}&query=${search}&tab=${tab}`;
    const {data} = yield call(processRequest, query, 'GET');
    if (data.posts) {
      const {i_block_user_ids = [], me_block_user_ids = []} = yield select(
        getUserSelector,
      );
      const posts =
        (filterBlackList({
          posts: data.posts,
          blackList: i_block_user_ids || [],
          blockedMe: me_block_user_ids || [],
          skipComments: false,
        })) || [];
      yield put(postsActions.getPostsSuccess(posts, data.total_pages));
    }
  } catch (e) {
    console.log(e);
    yield put(postsActions.getPostsError(e));
  }
}

function* handleGetPost(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const query = `/posts/${id}`;
    const {data} = yield call(processRequest, query, 'GET');
    if (data.post) {
      const {i_block_user_ids = [], me_block_user_ids = []} = yield select(
        getUserSelector,
      );
      const posts =
        (filterBlackList({
          posts: [data.post],
          blackList: i_block_user_ids || [],
          blockedMe: me_block_user_ids || [],
        })) || [];
      if (posts[0]) {
        yield put(
          postsActions.getPostSuccess({...posts[0], commentsOpened: true}),
        );
      } else {
        yield put(postsActions.getPostError(data));
      }
    }
  } catch (e) {
    const { message, data, status, statusText } = e || {};
    const {error_messages, error, error_message, messages} = data || {};

     if (status === 400) {
      console.log(message, data);
    }
    console.log(e);
    yield put(postsActions.getPostsError(e));
  }
}

// function* handleGetUserPosts(action) {
//   const {
//     payload: {userPostsMeta},
//   } = action || {};
//   const user_id = yield select(getUserId);
//   const {page, perPage, placeInStack} = userPostsMeta || {};
//   const getUserPostsSuccess =
//     page === 1
//       ? postsActions.getUserPostsSuccess
//       : postsActions.getUserPostsNextPageSuccess;

//   try {
//     const {data} = yield call(
//       processRequest,
//       `${API_URLS.USER_POSTS}?user_id=${user_id}&page=${page}&per_page=${perPage}`,
//       METHOD.GET,
//     );

//     if (data.posts) {
//       yield put(getUserPostsSuccess(data.posts, placeInStack));
//     } else {
//       yield put(postsActions.getUserPostsError(data));
//       yield put(
//         globalActions.toggleToast(
//           true,
//           'Something went wrong. Please try again.',
//         ),
//       );
//     }
//   } catch (e) {
//     const {data, status, statusText} = e || {};
//     const {error_messages, error, error_message, messages} = data || {};
//     if (status === 400 && messages) {
//       yield put(getUserPostsSuccess('privateAccount', placeInStack));
//     } else if (status === 400) {
//       let message = '';
//       if (error_message) message = error_message;
//       else if (error_messages) {
//         const keys = Object.keys(error_messages);
//         const errorMessage = error_messages[keys[0]];

//         message = error_messages && `${ERROR_TITLES[keys[0]]} ${errorMessage}`;
//       }

//       yield put(postsActions.getUserPostsError(message));
//       yield put(globalActions.toggleToast(true, message));
//     } else if (status === 401) {
//       yield put(authActions.forceLogout());
//       yield put(globalActions.toggleToast(true, error_message || error));
//     } else if (status === 500) {
//       yield put(postsActions.getUserPostsError(statusText));
//       yield put(globalActions.toggleToast(true, statusText));
//     } else if (e.message) {
//       yield put(postsActions.getUserPostsError(e.message));
//       yield put(globalActions.toggleToast(true, e.message));
//     } else {
//       yield put(postsActions.getUserPostsError('Internal server error.'));
//       yield put(globalActions.toggleToast(true, 'Internal server error.'));
//     }
//   }
// }

function* handleGetUserPosts(action) {
  const { payload: { meta, id } } = action || {};
  const { page, perPage } = meta;

  // const {page, perPage, placeInStack} = userPostsMeta || {};
  try {
    const {data} = yield call(processRequest, `/posts/by_user?user_id=${id}&page=${page}&per_page=${perPage}`);
    if(data.posts) {
      yield put(postsActions.getUserPostsSuccess(data.posts, data.total_pages))
    } else {
      yield put(postsActions.getUserPostsError(data))
    }
  } catch(e) {
    const {data, status, statusText} = e || {};
    console.log(status, statusText);
    yield put(postsActions.getUserPostsError(e))
  }
} 

function* handleLikePost(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const query = `/likes/like_for_post`;
    const requestPayload = {id};
    const {data} = yield call(processRequest, query, 'POST', requestPayload);
    if (data.likes_count || data.likes_count === 0) {
      yield put(postsActions.likePostSuccess(Number(id), data.likes_count));
    }
  } catch (e) {
    console.log(e);
    yield put(postsActions.likePostError(e));
  }
}

function* handleSendView(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const query = `/posts/${id}`;
    const {data} = yield call(processRequest, query, 'GET');
    if (data.post) {
      yield put(postsActions.sendViewSuccess(Number(id)));
    }
  } catch (e) {
    console.log(e);
    yield put(postsActions.getPostsError(e));
  }
}

function* handleDeletePost(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const query = `/posts/${id}`;
    const {data} = yield call(processRequest, query, 'DELETE');
    if (data.message === 'Post deleted') {
      yield put(postsActions.deletePostSuccess(Number(id)));
    }
  } catch (e) {
    console.log(e);
    yield put(postsActions.getPostsError(e));
  }
}

function* handleCreateReply(action) {
  try {
    const {payload} = action;
    const {body, id} = payload;
    const formData = new FormData();
    if (body && id) {
      formData.append('reply[post_id]', `${id}`);
      formData.append('reply[body]', body);
    }
    const {data} = yield call(
      processRequest,
      '/replies',
      'POST',
      formData,
      false,
    );
    if (data.reply) {
      yield put(postsActions.createReplySuccess(data.reply));
    } else yield put(postsActions.createReplyError(JSON.stringify(data)));
  } catch (e) {
    console.log(e);
    yield put(postsActions.createReplyError(e));
  }
}

function* handleLikeReply(action) {
  try {
    const {payload: {reply}} = action;
    const {id} = reply;
    const query = `/likes/like_for_reply`;
    const requestPayload = {id};
    const {data} = yield call(processRequest, query, 'POST', requestPayload);
    if (data.likes_count || data.likes_count === 0) {
      yield put(postsActions.likeReplySuccess(reply, data.likes_count));
    }
  } catch (e) {
    console.log(e);
    yield put(postsActions.likeReplyError(e));
  }
}

function* handleDeleteReply(action) {
  try {
    const {payload} = action;
    const {id, replyId} = payload;
    const {data} = yield call(processRequest, `/replies/${replyId}`, 'DELETE');
    if (data.message === 'Reply deleted') {
      yield put(postsActions.deleteReplySuccess(Number(id) || 0, replyId || 0));
    } else yield put(postsActions.deleteReplyError(JSON.stringify(data)));
  } catch (e) {
    console.log(e);
    yield put(postsActions.deleteReplyError(e));
  }
}

function* handleReportPost(action) {
  try {
    const {payload: {reportable}} = action;
    const {id, body, user_id} = reportable || {};
    const requestPayload = {report: {body}};
    const {data} = yield call(
      processRequest,
      `/reports/report_for_post?id=${id}`,
      'POST',
      requestPayload,
    );

    if (data.message) {
      yield put(postsActions.reportPostSuccess(id));
      if (user_id) yield put(profileActions.block(user_id));
      // add alert('we received your report, user is automatically added to your black list, you will not see any content from this user and will not be able to interact with each other untill you unblock them')
    } else {
      yield put(postsActions.reportPostError(data));
    }
  } catch (e) {
      yield put(postsActions.reportPostError(e));
  }
}

function* handleReportReply(action) {
  try {
    const {payload: {reportable}} = action;
    const {id, body, user_id} = reportable || {};
    const requestPayload = {report: {body}};
    const {data} = yield call(
      processRequest,
      `/reports/report_for_reply?id=${id}`,
      'POST',
      requestPayload,
    );

    if (data.message) {
      yield put(postsActions.reportReplySuccess(id));
      if (user_id) yield put(profileActions.block(user_id));
      // add alert('we received your report, user is automatically added to your black list, you will not see any content from this user and will not be able to interact with each other untill you unblock them')
    } else {
      yield put(postsActions.reportReplyError(data));
    }
  } catch (e) {
      yield put(postsActions.reportReplyError(e));
  }
}

export function* watchPostsSagas() {
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.GET_POSTS, handleGetPosts);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.GET_POST, handleGetPost);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.LIKE_POST, handleLikePost);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.SEND_VIEW, handleSendView);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.DELETE_POST, handleDeletePost);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.CREATE_REPLY, handleCreateReply);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.DELETE_REPLY, handleDeleteReply);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.LIKE_REPLY, handleLikeReply);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.GET_USER_POSTS, handleGetUserPosts);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.REPORT_POST, handleReportPost);
  yield takeEvery(POSTS_CONSTS.POSTS_ACTIONS.REPORT_REPLY, handleReportReply);
}