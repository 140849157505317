import React, { useEffect } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { getToken, getUser } from '../services/LocalStorage'
import { isMobile, isIOS } from 'react-device-detect'

const PrivateRoute = ({ redirectable = true, ...props }) => {
  const token = getToken()
  const user = getUser()
  // console.log(token)

    if(redirectable) {
      if(isMobile) {
      if(isIOS) {
      setTimeout(function () { window.location = "https://apps.apple.com/us/app/ilaugh-app/id1516551814"; }, 100);
      } else {
        setTimeout(function () { window.location = "https://play.google.com/store/apps/details?id=com.ilaugh&hl=en&gl=US"; }, 100); 
      }
        window.location.replace(`ilaugh://`);
        window.close()

        // window.location.reload();
    }
    }
   

  return (
    token && user?.is_finished_signup ? <Route {...props } /> : <Redirect to="/" />
  )
}

export default PrivateRoute
