import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { profileActions } from '../../redux/actions'
import Popup from '../Popup'

const PrivacyPopup = ({ open, handleClose }) => {
  const user = useSelector(state => state.authReducer.user)
  const [privacy, setPrivacy] = useState('')
  const dispatch = useDispatch()
  // const { register, handleSubmit, formState: { errors } } = useForm()
  
  useEffect(() => {
    setPrivacy(user?.profile_type)
  }, [user?.profile_type])

  const handleSubmit = e => {
    e.preventDefault()
    const userToPut = {
      ...user,
      profile_type: privacy
     }

    dispatch(profileActions.updateProfile(userToPut))
    handleClose()
  }

  return (
    <Popup open={open} handleClose={handleClose} className="profile-popup setting-popup">
        <h1>Privacy Settings</h1>

        <form onSubmit={handleSubmit}>
          <div className="profile-popup__inputs">
            <h4 className="popup-radio__headline">Account type</h4>
            <label className="popup-radio">
              <div className="popup-radio__text">
                <h3>Public</h3>
                <p>All users can see your publications and send messages to you</p>
              </div>
                <input checked={privacy === 'public_profile'} onClick={() => setPrivacy('public_profile')} type="radio" name="privacy" value="public" />
                <span className="checkmark">
                  <span className="circle"></span>
                </span>
            </label>
            <label className="popup-radio">
              <div className="popup-radio__text">
                <h3>Private</h3>
                <p>Publications and messages will be available only to people you follow</p>
              </div>
                <input checked={privacy === 'private_profile'} onClick={() => setPrivacy('private_profile')} type="radio" name="privacy" value="private" />
                <span class="checkmark">
                  <span className="circle"></span>
                </span>
            </label>
          </div>
          <input type="submit"  value="CONFIRM" />
        </form>
    </Popup>
  )
}

export default PrivacyPopup
