import {MESSAGING_CONSTS, INITIAL_STATE, PROFILE_CONSTS} from '../constants';
// import {sortMessagesByDay, sortChatsByDay, sortChats, sortMessagesByDate} from 'helpers';
import {sortChats} from '../../helpers';

// let badge = {};
// let badge_count = 0;
 
export const messagingReducer = (state = INITIAL_STATE.MESSAGING_STORE, action) => {
  const {type, payload} = action || {};
  // const { meta, chats, chat, messages, message, error } = payload || {};
  const {meta, messagesMeta, chats, chat, messages, message, total_pages, cable, user_id} =
    payload || {};

  switch (type) {
    case MESSAGING_CONSTS.GET_CHATS:
      return {
        ...state,
        meta,
      };
    case MESSAGING_CONSTS.GET_CHATS_SUCCESS: {
      // const sortedChats = sortChats(sortChatsByDay(chats));
      const chats_count = chats.length;
      const newChats = [...chats];
      state.chats.forEach((oldChat) => {
        const {id, unreaded_count} = oldChat || {};
        let i = 0;
        if (unreaded_count && unreaded_count > 0) {
          i = newChats.findIndex((el) => el.id === id);
          if (i !== -1) {
            newChats[i].unreaded_count = unreaded_count;
            newChats[i].unreaded = true;
          }
        }
      });

      return {
        ...state,
        chats: sortChats(newChats),
        chats_count,
        meta: {...state.meta, total_pages},
      };
    }
    case MESSAGING_CONSTS.GET_CHATS_NEXT_PAGE_SUCCESS: {
      const newChats = sortChats([...state.chats, ...chats]);
      const chats_count = newChats.length;

      return {
        ...state,
        chats: newChats,
        chats_count,
        meta: {...state.meta, total_pages},
      };
    }
    case MESSAGING_CONSTS.GET_MESSAGES: {
      return {
        ...state, messagesMeta
      }
    }
    case MESSAGING_CONSTS.GET_MESSAGES_SUCCESS: {
      const {date} = state.messagesMeta || {};
      let newMessages = []
      if(!date) {
        // refreshing
        newMessages = messages;
      } else {
        newMessages = [...state.messages]
        const ids = newMessages.map(el => el.id);
        messages.forEach(el => {
          if(ids.indexOf(el.id) === -1) newMessages.push(el);
        });
      }
      const newMeta = {...state.messagesMeta};
      if (messages.length === 0) newMeta.finished = true;
      return {
        ...state,
        messages: newMessages,
        messagesMeta: newMeta,
      };
    }
    case MESSAGING_CONSTS.WEB_SOCKET_CONNECTION: {
      return {
        ...state,
        cable: cable
      }
    }
    case MESSAGING_CONSTS.CREATE_CHAT_SUCCESS: {
      const newChats = [...state.chats];
      const index = newChats.findIndex((oldChat) => oldChat.id === chat.id);
      console.log({index})
      console.log({chat})
      if(index !== -1) {
        newChats[index] = {
          ...newChats[index],
          unreaded: false,
          unreaded_count: null,
        };
      } else newChats.unshift(chat)
      console.log({newChats})

      // if(state.chats.length > 0) {
      //   state.chats.map(oldChats => {
      //     oldChats.data.map(oldChat => {
      //       if(oldChat.id === chat.id) {
      //         newChats.push({
      //           ...oldChat,
      //           unreaded: false
      //         });
      //         if(badge[chat.id] && badge[chat.id] === 1) {
      //           badge = {
      //             ...badge,
      //             [chat.id]: 0
      //           };
      //           badge_count = badge_count - 1;
      //         }
      //       } else {
      //         newChats.push(oldChat);
      //       }
      //     });
      //   });
      // } else {
      //   newChats.push(chat);
      // }

      // const sortedNewChats = sortChats(sortChatsByDay(newChats));
      // const newMessages = sortMessagesByDate(sortMessagesByDay(messages));

      return {
        ...state,
        chat,
        messages,
        chats: sortChats(newChats),
        // badge_count
      };
    }
    // case MESSAGING_CONSTS.CREATE_CHAT_ERROR:
    //   return {
    //     ...state,
    //     error
    //   };
    case MESSAGING_CONSTS.RECEIVED_MESSAGE: {
      const newMessages = [...state.messages] || [];
      const newChats = [...state.chats] || [];
      const chatIndex = newChats.findIndex(el => el.id === state.chat.id);
      newChats[chatIndex].last_message = message;
      newChats[chatIndex].updated_at = message.created_at;
      // const newMessage = sortMessagesByDay([message]);
      // const messages_length = state.messages_length + 1;
      // const notFirst = newMessages.filter(oldMessages => oldMessages.title === newMessage[0].title)[0];

      // if(notFirst) {
      //   newMessages.forEach(oldMessages => oldMessages.title === notFirst.title ? oldMessages.data.push(newMessage[0].data[0]) : null);
      // } else {
      // newMessages.push(message);
      // }
      if(newMessages.map(el=>el.id).indexOf(message.id) === -1) newMessages.unshift(message);

      return {
        ...state,
        messages: newMessages,
        chats: sortChats(newChats),
      };
    }
    case MESSAGING_CONSTS.DELETE_MESSAGE_SUCCESS: {
      const newMessages = [...state.messages];
      const index = newMessages
        .map((oldMessage) => oldMessage.id)
        .indexOf(message.id);
      newMessages.splice(index, 1);

      // newMessages.splice(index, 1);

      // newMessages.map((newMessage, messagesIndex) => {
      //   newMessage.data.map((item, index) => {
      //     if(item.id === message.id) {
      //       newMessage.data.splice(index, 1);
      //       removedDataIndex = messagesIndex;
      //     }
      //   })
      // });

      // if(newMessages[removedDataIndex].data.length === 0)
      //   newMessages.splice(removedDataIndex, 1);

      return {
        ...state,
        messages: newMessages,
      };
    }
    case MESSAGING_CONSTS.CLEAR_CHAT:
      return {
        ...state,
        chat: {},
      };
    case MESSAGING_CONSTS.RECEIVED_CHAT: {
      const newChats = [...state.chats];

      // const badge_count = state.badge_count + 1;
      // let isOldChat = [];

      if (newChats.length === 0) {
        newChats.push(chat);
      } else {
        let index = -1;
        index = newChats.map((oldChat) => oldChat.id).indexOf(chat.id);
        if (index >= 0) {
          let unreaded_count = newChats[index].unreaded_count || 0;
          let unreaded = newChats[index].unreaded || false;
          if (
            chat.id !== state.chat?.id &&
            newChats[index].last_message?.id !== chat.last_message?.id
          ) {
            unreaded_count += 1;
            unreaded = true;
          }
          newChats[index] = {
            ...newChats[index],
            ...chat,
            unreaded,
            unreaded_count,
          };
        } else {
          newChats.unshift(chat);
        }
        // newChats.forEach(oldChat => {
        //   if()
        // });
      }

      // state.chats.forEach(oldChats => {
      //   if(isOldChat.length === 0)
      //     isOldChat = oldChats.data.filter(oldChat => oldChat.id === chat.id);
      // });

      // if(state.chats.length > 0) {
      //   if(isOldChat.length > 0) {
      //     state.chats.map(oldChats => {
      //       oldChats.data.map(oldChat => {
      //         if(oldChat.id === chat.id) {
      //           newChats.push({
      //             ...oldChat,
      //             unreaded: true,
      //             last_message: chat.last_message,
      //             updated_at: chat.updated_at
      //           });
      //           if(!badge[chat.id] || (badge[chat.id] && badge[chat.id] === 0)) {
      //             badge = {
      //               ...badge,
      //               [chat.id]: 1
      //             };
      //             badge_count++;
      //           }
      //         } else {
      //           newChats.push(oldChat);
      //         }
      //       });
      //     });
      //   } else {
      //     const newChat = {
      //       ...chat,
      //       unreaded: true
      //     };

      //     state.chats.forEach(oldChats => oldChats.data.map(oldChat => {
      //       newChats.push(oldChat);
      //     }));

      //     newChats.push(newChat);

      //     if(!badge[chat.id] || (badge[chat.id] && badge[chat.id] === 0)) {
      //       badge = {
      //         ...badge,
      //         [chat.id]: 1
      //       };
      //       badge_count++;
      //     }
      //   }
      // } else {
      //   const newChat = {
      //     ...chat,
      //     unreaded: true
      //   };
      //   newChats.push(newChat);

      //   if(!badge[chat.id] || (badge[chat.id] && badge[chat.id] === 0)) {
      //     badge = {
      //       ...badge,
      //       [chat.id]: 1
      //     };
      //     badge_count++;
      //   }
      // }

      // const sortedNewChats = sortChats(sortChatsByDay(newChats));

      return {
        ...state,
        chats: sortChats(newChats),
        // badge_count
      };
    }

    case MESSAGING_CONSTS.UPDATE_CHAT: {
      const newChats = [...state.chats];
      let index = -1;
      index = newChats.map((oldChat) => oldChat.id).indexOf(chat.id);
      if (index >= 0) newChats[index] = chat;
      return {
        ...state,
        chats: sortChats(newChats),
        // badge_count
      };
    }
    case MESSAGING_CONSTS.SET_INITIAL_STATE: {
      return INITIAL_STATE.MESSAGING_STORE;
    }
    case PROFILE_CONSTS.BLOCK_USER_SUCCESS: {
      const newChats = [...state.chats];
      const i = newChats.findIndex((el) => el.opponent.id === Number(user_id));
      if (i !== -1) {
        newChats.splice(i, 1);
      }
      return {
        ...state,
        chats: newChats,
      };
    }

    case MESSAGING_CONSTS.TOGGLE_MESSAGE_POPUP: {
      return {
        ...state,
        showMessagePopup: !state.showMessagePopup
      }
    }

    // case MESSAGING_CONSTS.SEND_MESSAGE_SUCCESS: {
    //   const newMessages = [...state.messages] || [];
    //   const newChats = [...state.chats] || [];

    //   return {
    //     ...state,
    //     messages: newMessages,
    //     chats: sortChats(newChats)
    //   }
    // }
    // case MESSAGING_CONSTS.UPDATE_CHAT: {
    //   const newChats = [];
    //   let isOldChat = [];

    //   state.chats.forEach(oldChats => {
    //     if(isOldChat.length === 0)
    //       isOldChat = oldChats.data.filter(oldChat => oldChat.id === chat.id)
    //   });

    //   if(state.chats.length > 0) {
    //     if(isOldChat.length > 0) {
    //       state.chats.map(oldChats => {
    //         oldChats.data.map(oldChat => {
    //           if(oldChat.id === chat.id) {
    //             newChats.push({
    //               ...oldChat,
    //               last_message: chat.last_message,
    //               updated_at: chat.updated_at
    //             });
    //           } else {
    //             newChats.push(oldChat);
    //           }
    //         });
    //       });
    //     } else {
    //       state.chats.forEach(oldChats => oldChats.data.map(oldChat => {
    //         newChats.push(oldChat);
    //       }));
    //       newChats.push(chat);
    //     }
    //   } else {
    //     newChats.push(chat);
    //   }

    //   const sortedNewChats = sortChats(sortChatsByDay(newChats));

    //   return {
    //     ...state,
    //     chats: sortedNewChats
    //   };
    // }
    case MESSAGING_CONSTS.LOGOUT_SUCCESS:
      return INITIAL_STATE.MESSAGING_STORE
    default:
      return state;
  }
};
