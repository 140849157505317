import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { eventsActions } from '../../redux/actions'
import Event from './Event'

const UpcommingEvents = () => {
  const dispatch = useDispatch()
  const events = useSelector(state => state.eventsReducer)

  useEffect(() => {
    dispatch(eventsActions.getEvents())
  }, [])
  
  return (
    <div class="events__list events__list--upcoming" id="upcoming">
      <div class="list">
        {events.events ? events.events.map((event, i) => <Event id={event.id} title={event.title} description={event.description} place={event.location} price={event.price} date={event.start_date} key={i} />) : null}
      </div>
    </div>
  )
}

export default UpcommingEvents
