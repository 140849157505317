import {filterBlackList} from '../../helpers';
import {INITIAL_STATE, PROFILE_CONSTS} from '../constants';

const profileReducer = (state = INITIAL_STATE.ProfileInitialState, action) => {
  const {type, payload} = action || {};
  const {users = [], user, id, error, total_pages, usersMeta, meta, ratingUsers, ratingUsersMeta} = payload || {};
  switch (type) {
    case PROFILE_CONSTS.PROFILE_ACTIONS.FOLLOW_SUCCESS: {
      const newUser = state.user;
      if (newUser && newUser.id === id) {
        newUser.is_followed = true;
        let count = newUser?.followers_count || 0;
        count += 1;
        newUser.followers_count = count;
      }
      const newUsers = [...state.users];
      newUsers.forEach((item, index) => {
        if (item && item.id === id) {
          newUsers[index].is_followed = true;
          let count = item?.followers_count || 0;
          count += 1;
          newUsers[index].followers_count = count;
        }
      });
      return {
        ...state,
        users: newUsers,
        user: newUser,
      };
    }
    case PROFILE_CONSTS.PROFILE_ACTIONS.UNFOLLOW_SUCCESS: {
      const newUser = state.user;
      if (newUser && newUser.id === id) {
        newUser.is_followed = false;
        let count = newUser?.followers_count || 1;
        count -= 1;
        newUser.followers_count = count;
      }
      const newUsers = [...state.users];
      newUsers.forEach((item, index) => {
        if (item && item.id === id) {
          newUsers[index].is_followed = false;
          let count = item?.followers_count || 1;
          count -= 1;
          newUsers[index].followers_count = count;
        }
      });
      return {
        ...state,
        users: newUsers,
        user: newUser,
      };
    }
    case PROFILE_CONSTS.PROFILE_ACTIONS.BLOCK_SUCCESS: {
      const blackList = [Number(id)];
      const newUsers = filterBlackList({
        users: state.users,
        blackList,
        blockedMe: [],
      });
      const newRatings = filterBlackList({
        users: state.ratings,
        blackList,
        blockedMe: [],
      });
      const newFollowers = filterBlackList({
        users: state.followers,
        blackList,
        blockedMe: [],
      });
      const newFollowing = filterBlackList({
        users: state.following,
        blackList,
        blockedMe: [],
      });
      return {
        ...state,
        users: newUsers,
        ratings: newRatings,
        followers: newFollowers,
        following: newFollowing,
      };
    }
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_RATINGS:
      return {
        ...state,
        ratingUsersMeta,
      };
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_RATINGS_SUCCESS:
      return {
        ...state,
        ratingUsers,
        ratingUsersMeta: {
          ...state.ratingUsersMeta,
          total_pages,
        },
      };
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_RATINGS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        ratingUsers: [...state.ratingUsers, ...ratingUsers],
        ratingUsersMeta: {
          ...state.ratingUsersMeta,
          total_pages,
        },
      };
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_USER_DETAILS_SUCCESS: {
      return {...state, user: user || state.user};
    }
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_USERS:
      return {
        ...state,
        usersMeta,
      };
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_USERS_SUCCESS:
      return {
        ...state,
        users,
        usersMeta: {...state.usersMeta, total_pages},
      };
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_USERS_NEXT_PAGE_SUCCESS: {
      const newUsers = [...state.users];
      const lastUsers = state.users.slice(-20).map((user) => user.id);
      // testArr.push(users.slice(-2));
      for (let i = 0; i < users.length; i += 1) {
        if (lastUsers.indexOf(users[i].id) === -1) {
          newUsers.push(users[i]);
        }
      }
      return {
        ...state,
        users: newUsers,
        usersMeta: {...state.usersMeta, total_pages},
      };
    }
    case PROFILE_CONSTS.PROFILE_ACTIONS.FOLLOW_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.UNFOLLOW_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.BLOCK_UNBLOCK_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_CURRENT_USER_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_USER_DETAILS_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.UPLOAD_AVATAR_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.UPDATE_PROFILE_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.UPDATE_PASSWORD_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_FOLLOWERS_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_FOLLOWING_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_USERS_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_RATINGS_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.REPORT_USER_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_BLACK_LIST_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.SEND_DONATION_ERROR:
    case PROFILE_CONSTS.PROFILE_ACTIONS.GET_DONATIONS_ERROR:
      return {
        ...state,
        error,
      };
      case PROFILE_CONSTS.PROFILE_ACTIONS.LOGOUT_SUCCESS:
        return INITIAL_STATE.ProfileInitialState
    default:
      return state;
  }
};

export {profileReducer};