import React from 'react'
import Button from './Button'
import { globalActions } from '../../redux/actions'
import { useDispatch } from 'react-redux'
 
const LoginButton = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    console.log(21231)
    dispatch(globalActions.toggleLoginModal(true, false))
  }
  return (
    <Button handleClick={handleClick}>Login</Button>
  )
}

export default LoginButton
