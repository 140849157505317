import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Header from '../components/Header'
import FeedPosts from '../components/Feed/FeedPosts'
import Sidebar from '../components/Sidebar/Sidebar'
import SuccessPopup from '../components/SuccessPopup/SuccessPopup'
import ReportModal from '../components/ReportModal/ReportModal'
import AppWrapper from '../components/AppWrapper';

const Feed = () => {
  const isAuthenticated = useSelector(state => state.authReducer.user)
  const history = useHistory()

  useEffect(() => {
	  const redirect = localStorage.getItem('event') ? localStorage.getItem('event') : '';
    if (redirect) {
      history.push(`/buy-ticket/${redirect}`);
    }
  }, []);

	useEffect(() => {
		if(!isAuthenticated) {
			history.push('/')
		}
	}, [isAuthenticated])

  return (
    <AppWrapper>
      <svg style={{width: '0', height: '0', position: 'absolute'}} aria-hidden="true" focusable="false">
        <linearGradient id="my-cool-gradient" x2="1" y2="0">
        <stop offset="0%" stopColor="#e25867" />
        <stop offset="100%" stopColor="#e88969" />
      </linearGradient>
      </svg>

      <SuccessPopup />
      <ReportModal />

      <Header tabs />
      <div className="page__wrapper page__bg page__bg--blur feed-page">
        <div className="page__feed page__posts">
          <Sidebar />
          <FeedPosts />
        </div>
      </div>
    </AppWrapper>
  )
}

export default Feed
