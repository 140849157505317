import React from 'react'
import Header from '../components/Header'
import ProfileComponent from '../components/Profile/ProfileComponent'
import Sidebar from '../components/Sidebar/Sidebar'
import SuccessPopup from '../components/SuccessPopup/SuccessPopup'
import AppWrapper from '../components/AppWrapper';

const Profile = () => {
  return (
    <AppWrapper oveflow="unset">
      <svg style={{width: '0', height: '0', position: 'absolute'}} aria-hidden="true" focusable="false">
        <linearGradient id="my-cool-gradient" x2="1" y2="0">
        <stop offset="0%" stopColor="#e25867" />
        <stop offset="100%" stopColor="#e88969" />
      </linearGradient>
      </svg>

      <SuccessPopup />
      <Header />
      <div className="page__wrapper page__bg page__bg--blur feed-page">
        <div className="page__feed">
          <Sidebar />
          <ProfileComponent />
        </div>
      </div>
    </AppWrapper>
  )
}

export default Profile
